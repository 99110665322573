import styled from "styled-components";
import { ChevronRight, ChevronLeft } from "@styled-icons/boxicons-regular";

import { colors, fonts } from "styles";
import DefaultImage from "assets/images/default-header-image.jpg";

export const MainContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  margin-top: -89px;
  position: relative;
  .slick-prev:before,
  .slick-next:before {
    content: "";
    display: none;
  }
`;

type SlideContract = {
  bgImage?: string | null;
};

export const Overlay = styled.div`
  width: 100%;
`;

export const Slide = styled.div<SlideContract>`
  /* width: 99% !important; */
  height: 100vh;
  background-color: #fff;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    ${({ bgImage }) => `url('${bgImage || DefaultImage}')`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 128px;

  @media screen and (max-width: 1250px) {
    width: 100% !important;
  }

  @media screen and (max-width: 500px) {
    padding: 0 24px;
  }
`;

// featured content
export const FeaturedContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  pointer-events: none;
`;

export const FeaturedContent = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  color: #fff;
  font-family: ${fonts.Muli};

  @media screen and (max-width: 1250px) {
    max-width: max-content;
  }
`;

export const BuildingType = styled.h4`
  color: ${colors.orange};
  font-size: 18px;
  text-transform: uppercase;

  @media screen and (max-width: 1250px) {
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const Title = styled.h1`
  font-family: ${fonts.NexaBold};
  font-size: 72px;

  @media screen and (max-width: 1250px) {
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 44px;
    line-height: 44px;
  }
`;

export const Subtitle = styled.h3`
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 16px;

  @media screen and (max-width: 1250px) {
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const BuildingLocale = styled.p`
  font-size: 15px;

  @media screen and (max-width: 1250px) {
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

// slider controls
export const Controls = styled.div.attrs({ className: "slider-controls" })`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  padding: 0 60px;
  width: 100%;
  top: 89px;
  bottom: 0;
  pointer-events: none;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const ControlButton = styled.button.attrs({ type: "button" })`
  display: flex;
  flex: 0 0 64px;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: ${colors.dark};
  color: #fff;
  pointer-events: initial;
  transition: transform 300ms linear;

  &:hover {
    transform: scale(0.9);
  }
`;

export const PrevButton = styled(ControlButton)``;
export const NextButton = styled(ControlButton)``;

export const PrevIcon = styled(ChevronLeft).attrs({ size: 32 })``;
export const NextIcon = styled(ChevronRight).attrs({ size: 32 })``;

export const BottomBarContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;

  @media (max-width: 1250px) {
    position: relative;
    bottom: unset;
    height: auto;
  }
`;
