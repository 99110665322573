type ErrorPayload = {
  errorMessage: string;
  validationErrors?: Record<string, any>[];
  errorCode?: string;
};

function parseErrorMessage(message: string, code: string): string {
  return message?.replace(`${code}:`, "").trim() || "";
}

export function requestErrorHandler(error: any | Error): ErrorPayload {
  if (error?.response?.data) {
    const {
      message: errorMessage,
      validationErrors = [],
      code: errorCode,
    } = error.response.data;

    return {
      errorMessage:
        parseErrorMessage(errorMessage, errorCode) ||
        "Erro ao executar sua requisição",
      validationErrors,
      errorCode,
    };
  }

  return {
    errorMessage: error.message,
    validationErrors: [],
    errorCode: "E_ERROR",
  };
}
