import React, { useEffect, useCallback, useRef } from "react";

import * as S from "./styles";

type Props = {
  features?: string | null;
  proximity?: string | null;
};

export const FeaturesAndProximity: React.FC<Props> = ({
  features = null,
  proximity = null,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const addBottomPaddingToPreviousSibling = useCallback(() => {
    const prevSibling = ref?.current?.previousElementSibling;
    if (prevSibling) {
      prevSibling.classList?.add("next-sibling-is-features-and-proximity");
    }
  }, []);

  useEffect(() => {
    addBottomPaddingToPreviousSibling();
  }, [addBottomPaddingToPreviousSibling]);

  return (
    <S.MainContainer ref={ref}>
      <S.MainContent>
        {features && (
          <S.Features>
            <S.Title>Características:</S.Title>
            <p>{features}</p>
            <S.FeaturesIcon />
          </S.Features>
        )}
        {proximity && (
          <S.Features>
            <S.Title>Proximidades:</S.Title>
            <p>{proximity}</p>
            <S.ProximityIcon />
          </S.Features>
        )}
      </S.MainContent>
    </S.MainContainer>
  );
};
