import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import { PaginatedBuilding } from "contracts/buildings";
import { Pagination } from "contracts/common";

export interface PaginateBuildingsState {
  data: PaginatedBuilding[];
  pagination: Pagination | null;
  loading: boolean;
  error: string | null;
}

export interface PaginateBuildingsRequest {
  query: Record<string, any>;
  onSuccess?(): void;
  onFailure?(): void;
}

interface SuccessAction {
  data: PaginatedBuilding[];
  pagination: Pagination;
}

interface FailureAction {
  error: string;
}

const { Types, Creators } = createActions(
  {
    request: ["query", "onSuccess", "onFailure"],
    success: ["data", "pagination"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "PAGINATE_BUILDINGS_" }
);

const INITIAL_STATE: PaginateBuildingsState = {
  data: [],
  pagination: null,
  loading: false,
  error: null,
};

const request = (state: PaginateBuildingsState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: PaginateBuildingsState, action: SuccessAction) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
    pagination: { $set: action.pagination },
  });

const failure = (state: PaginateBuildingsState, action: FailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const paginateBuildings = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const PaginateBuildingsTypes = Types;
export const PaginateBuildingsActions = Creators;
