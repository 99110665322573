import styled from "styled-components";
import { colors, fonts } from "styles";
import { Button } from "styles/styled-components";

export const Container = styled.section`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 28px;
  font-family: ${fonts.Muli};
  font-weight: 300;
  color: ${colors.blueGray};
  text-align: center;
  width: 100%;
  max-width: 790px;
  margin-bottom: 48px;
  padding-top: 72px;
  border-top: 1px #b6b9bd solid;

  @media screen and (max-width: 375px) {
    font-size: 24px;
  }
`;

export const BuildingWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;

  .slick-prev:before,
  .slick-next:before {
    content: "";
    display: none;
  }
`;

export const SeeAllButton = styled(Button)``;
