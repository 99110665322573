import { call, put } from "redux-saga/effects";
import { api, notify } from "services";
import { requestErrorHandler, applyQueryParams } from "utils";
import {
  ListPortfolioBuildingsActions as Actions,
  ListPortfolioBuildingsRequest as RequestActions,
} from "store/ducks/portfolioBuildings";

export function* listPortfolioBuildingsRequest(action: any) {
  const { query = {}, onSuccess, onFailure }: RequestActions = action;
  try {
    const url = applyQueryParams("portfolio-buildings", {
      ...query,
      asList: 1,
    });
    const { data } = yield call(api.get, url);
    onSuccess && onSuccess();
    yield put(Actions.success(data));
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    onFailure && onFailure();
    notify("error", errorMessage);
    yield put(Actions.failure(errorMessage));
  }
}
