import React, { useCallback, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import { format, isBefore } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { MilestoneGalleryImage } from "contracts/buildings";
import { Select } from "components/Shared/Form";
import { Modal } from "components/Shared/Modal";
import * as S from "./styles";

type Props = {
  images: MilestoneGalleryImage[];
};

interface Option {
  label: string;
  value: number;
}

const sliderSettings: Settings = {
  slidesToShow: 3,
  infinite: false,
  speed: 500,
  nextArrow: (
    <S.NextArrow>
      <S.RightIcon />
    </S.NextArrow>
  ),
  prevArrow: (
    <S.PrevArrow>
      <S.LeftIcon />
    </S.PrevArrow>
  ),

  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 2,
        arrows: false,
        dots: true,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        arrows: false,
        dots: true,
      },
    },
  ],
};

const orderByDate = (
  prev: MilestoneGalleryImage,
  curr: MilestoneGalleryImage
): number => {
  const prevDate = new Date(prev.reference_date);
  const currDate = new Date(curr.reference_date);

  if (isBefore(currDate, prevDate)) return -1;
  if (isBefore(prevDate, currDate)) return 1;
  return 0;
};

export const MilestoneGallery: React.FC<Props> = ({ images }) => {
  const formRef = useRef<FormHandles | null>(null);
  const [selectedYear, setSeletedYear] = useState<number | null>(null);

  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [ImgSrc, setImgSrc] = useState<string>("");

  const handleSelectorOnChange = useCallback((option) => {
    setSeletedYear(option?.value || null);
  }, []);

  const SelectorComponent: React.FC = useCallback(() => {
    const years: number[] = Array.from(
      new Set(images.map((i) => new Date(i.reference_date)?.getFullYear()))
    );

    const options: Option[] = years
      .map((i) => ({
        label: `${i}`,
        value: i,
      }))
      .sort((prev, curr) => {
        if (prev.value < curr.value) return 1;
        if (prev.value > curr.value) return -1;
        return 0;
      });

    // why show a selector when theres less then 2 options?
    if (options.length < 2) return <></>;

    return (
      <Form ref={formRef} onSubmit={() => {}}>
        <Select
          name="year"
          onChange={handleSelectorOnChange}
          placeholder="Selecione o ano"
          options={[...[{ label: "Todos", value: null }], ...options]}
        />
      </Form>
    );
  }, [handleSelectorOnChange, images]);

  const GalleryComponent: React.FC = useCallback(() => {
    function handleModal(src: string) {
      setPopupOpen(true);
      setImgSrc(src);
    }
    return (
      <S.Gallery>
        <Slider {...sliderSettings}>
          {images
            .sort(orderByDate)
            .filter((image) => {
              if (!selectedYear) return true;
              return (
                new Date(image.reference_date)?.getFullYear() === selectedYear
              );
            })
            .map((image) => (
              <S.Slide key={image.id}>
                <S.SlideImage
                  onClick={() => handleModal(image.filepath)}
                  src={image.filepath}
                  alt={image.description || ""}
                />
                <S.SlideDate>
                  {format(new Date(image.reference_date), "MMMM, yyyy", {
                    locale: ptBR,
                  })}
                </S.SlideDate>
                <S.ImageDescription>{image.description}</S.ImageDescription>
              </S.Slide>
            ))}
        </Slider>
      </S.Gallery>
    );
  }, [images, selectedYear]);

  return (
    <>
      <Modal isOpen={popupOpen} onClickOutside={() => setPopupOpen(false)}>
        <S.ModalContent>
          <S.CloseButton onClick={() => setPopupOpen(false)}>
            &times;
          </S.CloseButton>
          <S.ImageContaienr>
            <img src={ImgSrc} alt="Imagem do Empreendimento" />
          </S.ImageContaienr>
        </S.ModalContent>
      </Modal>

      <S.MainContainer>
        <S.Header>
          <S.HeaderTitle>Galeria de etapas</S.HeaderTitle>
          <S.HeaderSubtitle>
            Acompanhe mensalmente a evolução do empreendimento.
          </S.HeaderSubtitle>
          <SelectorComponent />
        </S.Header>
        <GalleryComponent />
      </S.MainContainer>
    </>
  );
};
