import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidV4 } from "uuid";

import * as S from "./styles";

type Props = {
  transform?: boolean;
};

interface IOption {
  id: string;
  name: string;
  route: string;
}

const options: Array<IOption> = [
  {
    id: uuidV4(),
    name: "Empreendimento à venda",
    route: "/",
  },
  {
    id: uuidV4(),
    name: "Sobre nós",
    route: "/sobre-nos",
  },
  {
    id: uuidV4(),
    name: "Portfólio",
    route: "/portfolio",
  },
  {
    id: uuidV4(),
    name: "Acompanhe sua obra",
    route: "/acompanhe-sua-obra",
  },
];

export const Navigation: React.FC<Props> = ({ transform = false }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const handleNavigation = useCallback(
    (route: string) => {
      history.push(route);
    },
    [history]
  );
  return (
    <S.Container>
      <S.Web>
        {options.map((option) => (
          <S.Option
            key={option.id}
            onClick={() => handleNavigation(option.route)}
            transform={transform ? "yes" : "no"}
          >
            {option.name}
          </S.Option>
        ))}
      </S.Web>
      <S.MenuButton onClick={() => setOpen(true)}>
        <S.MenuIcon />
      </S.MenuButton>
      <S.Overlay open={open}>
        <S.CloseButton onClick={() => setOpen(false)}>
          <S.CloseIcon />
        </S.CloseButton>
        <S.Logo />
        <S.OptionsWrapper>
          {options.map((option) => (
            <S.Option
              key={option.id}
              onClick={() => handleNavigation(option.route)}
            >
              {option.name}
            </S.Option>
          ))}
        </S.OptionsWrapper>
      </S.Overlay>
    </S.Container>
  );
};
