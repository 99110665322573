import styled from "styled-components";
import { colorScheme, colors } from "styles";

export const ContentContainer = styled.div`
  background-color: #ededed;
`;

export const Content = styled.section`
  width: 100%;
  max-width: 1304px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 32px;
  margin: 0 auto;
`;

export const Title = styled.h2`
  font-size: 36px;
  color: ${colors.dark};
  text-align: center;
  width: 100%;
  max-width: 420px;
  margin-bottom: 56px;
  text-align: center;

  span {
    color: ${colorScheme.secondary};
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
  }

  @media screen and (max-width: 375px) {
    font-size: 24px;
  }
`;

export const ItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 72px;
  position: relative;

  @media screen and (max-width: 860px) {
    flex-direction: column;
  }
`;

export const Item = styled.div`
  width: 33%;
  margin-right: 40px;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 860px) {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ItemTitle = styled.h3`
  font-size: 20px;
  color: ${colors.dark};
  margin-bottom: 24px;
  text-transform: uppercase;

  @media screen and (max-width: 860px) {
    text-align: center;
  }

  @media screen and (max-width: 375px) {
    font-size: 18px;
  }
`;

export const ItemText = styled.article`
  font-size: 16px;
  line-height: 20px;
  color: ${colorScheme.text};

  @media screen and (max-width: 860px) {
    text-align: center;
  }
`;
