import React, { PropsWithChildren } from "react";

import { Header, Footer } from "components/Shared";
import * as S from "./styles";

interface Props extends PropsWithChildren<any> {
  background?: boolean;
}

export const Scaffold: React.FC<Props> = ({ children, background }) => {
  return (
    <S.Container>
      <Header background={background} />
      {children}
      <Footer />
    </S.Container>
  );
};
