import React, { useCallback, useRef } from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { PaginatedBuilding } from "contracts/buildings";
import { Select } from "components/Shared/Form";
import * as S from "./styles";

type Props = {
  onBuildingSelected?(buildingId: number): void;
  buildings: PaginatedBuilding[];
  isLoading: boolean;
};

export const Header: React.FC<Props> = ({
  onBuildingSelected,
  buildings,
  isLoading,
}) => {
  const formRef = useRef<FormHandles | null>(null);

  const handleSelectChange = useCallback(
    (option) => {
      if (onBuildingSelected && option?.value) {
        onBuildingSelected(Number(option.value));
      }
    },
    [onBuildingSelected]
  );

  const SelectorComponent: React.FC = useCallback(() => {
    if (isLoading) return <S.ActivityIndicator />;

    type Option = {
      label: string;
      value: number;
    };
    const options: Option[] = buildings
      .map((b) => ({
        label: b.name,
        value: b.id,
      }))
      .sort((prev, curr) => {
        if (curr.label > prev.label) return -1;
        if (curr.label < prev.label) return 1;
        return 0;
      });
    return (
      <Select
        name="building"
        label="Selecione o empreendimento"
        placeholder="Selecione"
        options={options}
        onChange={handleSelectChange}
      />
    );
  }, [buildings, handleSelectChange, isLoading]);

  return (
    <S.MainContainer>
      <S.ContentContrainer>
        <S.Header>
          <S.Title>
            Acompanhe
            <br />
            sua obra
          </S.Title>
          <S.Subtitle>
            Veja o andamento e etapas dos nossos empreendimentos em construção.
          </S.Subtitle>
        </S.Header>
        <S.MainContent>
          <Form ref={formRef} onSubmit={() => {}}>
            <S.SelectorLabel>Selecione o Empreendimento:</S.SelectorLabel>
            <SelectorComponent />
          </Form>
          {/* <S.ContactArea>
            <S.WhatsIcon />
            <S.ContactText>
              Fale com nosso corretor
              <br />
              <span>(24) 3348-1020</span>
            </S.ContactText>
          </S.ContactArea> */}
        </S.MainContent>
      </S.ContentContrainer>
    </S.MainContainer>
  );
};
