import styled from "styled-components";
import { colors, colorScheme, fonts } from "styles";
export { Button, LinkButton } from "styles/styled-components";

export const Container = styled.section`
  width: 100%;
  margin: 0 auto;
  position: relative;
  background-color: white;
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  max-height: 680px;
  background-color: ${colors.lightGray};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 80px 32px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 36px;
  color: ${colors.dark};
  text-align: center;
  width: 100%;
  max-width: 800px;
  margin-bottom: 72px;
  padding: 0 96px 48px 96px;
  border-bottom: 1px #acafb3 solid;

  span {
    color: ${colorScheme.secondary};
  }

  @media screen and (max-width: 500px) {
    font-size: 30px;
    padding: 0 0 48px 0;
  }
  @media screen and (max-width: 375px) {
    font-size: 24px;
  }
`;

export const Subtitle = styled.h3`
  width: 100%;
  font-family: ${fonts.Muli};
  font-size: 24px;
  font-weight: 300;
  color: ${colors.dark};
  text-align: center;
  margin-bottom: 48px;
`;

export const Wrapper = styled.div`
  max-width: 1270px;
  width: fit-content;
  display: grid;
  grid-template-columns: auto auto auto;
  margin: 0 auto 80px auto;
  column-gap: 24px;
  row-gap: 24px;

  @media screen and (max-width: 1280px) {
    max-width: 824px;
    grid-template-columns: auto auto;
  }

  @media screen and (max-width: 890px) {
    max-width: 400px;
    grid-template-columns: 100%;
  }
`;

export const SoldTitle = styled.h3`
  width: 100%;
  max-width: 800px;
  padding: 48px 96px 0 96px;
  font-size: 36px;
  color: ${colors.dark};
  text-align: center;
  margin-bottom: 24px;
  border-top: 1px #acafb3 solid;

  @media screen and (max-width: 500px) {
    font-size: 30px;
    padding: 48px 0 0 0;
  }
  @media screen and (max-width: 375px) {
    font-size: 24px;
  }
`;

export const SoldText = styled.article`
  width: 100%;
  max-width: 800px;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 48px;
  text-align: center;
  max-width: 530px;

  p {
    margin-bottom: 16px;

    :last-child {
      margin-bottom: 0;
    }
  }
`;
