import styled from "styled-components";
import { Whatsapp } from "@styled-icons/bootstrap/Whatsapp";
import { ChatLeft } from "@styled-icons/bootstrap/ChatLeft";
import { colors, fonts } from "styles";
import { Button } from "styles/styled-components";

export const MainContainer = styled.section`
  background-color: ${colors.dark};
  padding: 32px 0px 84px 0px;
  @media screen and (max-width: 890px) {
    padding: 32px 32px 84px 32px;
  }
`;

export const ContentConstrainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 840px;
  color: #fff;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 840px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 40%;
  @media screen and (min-width: 841px) {
    margin-right: 54px;
  }
  @media screen and (max-width: 840px) {
    margin-bottom: 54px;
  }
`;

export const Tittle = styled.h3`
  font-family: ${fonts.NexaBold};
  font-size: 34px;
  margin-bottom: 18px;
`;

export const Text = styled.p`
  font-size: 15px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 1 auto;
  @media screen and (min-width: 321px) {
    button:not(:last-child) {
      margin-right: 16px;
    }
  }
  @media screen and (max-width: 840px) {
    justify-content: center;
  }
  @media screen and (max-width: 320px) {
    flex-direction: column;
    button:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

export const ActionButton = styled(Button)`
  @media screen and (max-width: 320px) {
    width: 100%;
  }
`;

export const WhatsIcon = styled(Whatsapp).attrs({
  size: 24,
  color: colors.orange,
})`
  margin-right: 8px;
`;

export const WhatsLink = styled.a`
  color: #fff;
`;

export const ChatIcon = styled(ChatLeft).attrs({
  size: 24,
})`
  margin-right: 8px;
`;
