import styled from "styled-components";
import { ThumbsUp } from "@styled-icons/feather/ThumbsUp";

import DefaultBgImage from "assets/images/default-featured-image.jpg";

import PlanningImg from "assets/images/steps/planning.svg";
import LandworkImg from "assets/images/steps/landwork.svg";
import FoundationsImg from "assets/images/steps/foundations.svg";
import StructureImg from "assets/images/steps/structure.svg";
import BrickworkImg from "assets/images/steps/brickwork.svg";
import InstallationsImg from "assets/images/steps/installations.svg";
import FacadeImg from "assets/images/steps/facade.svg";
import FinishingImg from "assets/images/steps/finishing.svg";

import { colors, fonts } from "styles";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

// image content
interface IImageContent {
  bgImage: string | null;
}

export const ImageContent = styled.div<IImageContent>`
  flex: 1 1 60%;
  background-image: ${({ bgImage }) => `url('${bgImage || DefaultBgImage}')`};
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  position: relative;

  @media screen and (max-width: 960px) {
    flex: 1 0 auto;
    min-height: 200px;
    height: 20vh;
  }
`;

export const SalesPercentage = styled.div`
  width: 100px;
  height: 130px;
  background-color: ${colors.blue};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-bottom: 8px;
  font-family: ${fonts.NexaBold};

  position: absolute;
  left: 56px;
  z-index: 2;

  @media screen and (max-width: 960px) {
    bottom: -40px;
    left: auto;
    right: 43px;
  }
`;

export const ThumbsUpIcon = styled(ThumbsUp).attrs({
  size: 28,
  color: colors.orange,
})`
  stroke-width: 2px;
  margin-bottom: 16px;
`;

// main content
export const MainContent = styled.div`
  flex: 1 0 40%;
  background-color: #f2f2f2;
  min-height: 700px;
  padding: 80px 90px;

  @media screen and (max-width: 960px) {
    flex: 1 0 100%;
    padding: 32px 32px;
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid #cecece;
  padding-bottom: 26px;
  margin-bottom: 52px;
`;

export const Title = styled.h4`
  max-width: 190px;
  font-size: 36px;
  margin-bottom: 8px;
  color: #000;
`;

export const Subtitle = styled.p`
  font-size: 16px;
`;

// step

export const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
`;

export const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
`;

export const StepHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-transform: uppercase;
  margin-bottom: 12px;
  font-size: 15px;
  @media screen and (max-width: 460px) {
    font-size: 13px;
  }
`;

export const HeaderTitle = styled.span``;

export const HeaderPercentage = styled.span``;

export const CompletionBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
`;

interface ICompletionLevel {
  completion: number;
}
export const CompletionLevel = styled.div<ICompletionLevel>`
  width: ${({ completion }) => `${completion}%`};
  height: inherit;
  background-color: ${colors.orange};
`;

// icons

export const IconContainer = styled.div`
  width: 64px;
  @media screen and (max-width: 460px) {
    width: 48px;
  }
`;

const ImageIcon = styled.img`
  max-width: 30px;
  max-height: 30px;
  height: auto;
  @media screen and (max-width: 460px) {
    max-width: 20px;
    max-height: 20px;
  }
`;

export const PlanningIcon = styled(ImageIcon).attrs({
  src: PlanningImg,
})``;
export const LandworkIcon = styled(ImageIcon).attrs({
  src: LandworkImg,
})``;
export const FoundationsIcon = styled(ImageIcon).attrs({
  src: FoundationsImg,
})``;
export const StructureIcon = styled(ImageIcon).attrs({
  src: StructureImg,
})``;
export const BrickworkIIcon = styled(ImageIcon).attrs({
  src: BrickworkImg,
})``;
export const InstallationsIcon = styled(ImageIcon).attrs({
  src: InstallationsImg,
})``;
export const FacadeIcon = styled(ImageIcon).attrs({
  src: FacadeImg,
})``;
export const FinishingIcon = styled(ImageIcon).attrs({
  src: FinishingImg,
})``;
