import styled from "styled-components";
import { ChevronLeft, ChevronRight } from "@styled-icons/boxicons-regular";
import { colors, fonts } from "styles";

export { ActivityIndicator } from "styles/styled-components";

export const Container = styled.section`
  width: 100%;
  margin: 0 auto;
  position: relative;
  background-color: ${colors.lightGray};
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 80px 32px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 36px;
  color: ${colors.dark};
  text-align: center;
  width: 100%;
  max-width: 800px;
  margin-bottom: 72px;

  @media screen and (max-width: 500px) {
    font-size: 30px;
    padding: 0 0 48px 0;
  }
  @media screen and (max-width: 375px) {
    font-size: 24px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1366px;
  height: 550px;

  @media screen and (max-width: 1280px) {
    flex-direction: column-reverse;
    height: auto;
    max-width: 834px;
  }
`;

export const Gallery = styled.div`
  width: 100%;
  height: inherit;
  max-width: 834px;
  margin-right: 40px;
  position: relative;

  .slick-prev:before,
  .slick-next:before {
    content: "";
    display: none;
  }

  @media screen and (max-width: 1280px) {
    margin-right: 0;
  }
`;

interface ISlide {
  imageSrc: string;
}

export const Slide = styled.div<ISlide>`
  height: 550px;
  width: 100%;
  background-image: url(${({ imageSrc }) => imageSrc});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const About = styled.div`
  width: 100%;
  height: 100%;
  max-width: 400px;
  flex: 1;
  background-color: white;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1280px) {
    margin-bottom: 40px;
    flex-direction: row;
    max-width: 100%;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Logo = styled.img`
  margin-bottom: 32px;

  @media screen and (max-width: 1280px) {
    margin-bottom: 0;
    margin-right: 32px;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 32px;
  }
`;

export const TextWrapper = styled.div``;

export const AboutTitle = styled.h2`
  font-family: ${fonts.Muli};
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: ${colors.blue};
  margin-bottom: 24px;

  @media screen and (max-width: 1280px) {
    text-align: left;
  }

  @media screen and (max-width: 500px) {
    text-align: center;
  }
`;

export const AboutText = styled.div`
  text-align: center;
  font-size: 15px;
  line-height: 20px;
  color: ${colors.blueGray};

  @media screen and (max-width: 1280px) {
    text-align: left;
  }

  @media screen and (max-width: 500px) {
    text-align: center;
  }
`;

export const PrevArrow = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: white;
  position: absolute;
  left: 32px;
  z-index: 99;

  &:hover,
  &:focus {
    color: initial !important;
    background: white !important;
  }

  @media screen and (max-width: 500px) {
    display: none !important;
  }
`;

export const NextArrow = styled(PrevArrow)`
  left: auto;
  right: 32px;
  z-index: 99;
`;

export const IconChevronLeft = styled(ChevronLeft).attrs({ size: 24 })`
  color: ${colors.dark};
`;

export const IconChevronRight = styled(ChevronRight).attrs({ size: 24 })`
  color: ${colors.dark};
`;
