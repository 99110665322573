import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import { PaginatedAboutUsBanner } from "contracts/aboutUsBanners";

export interface ListAboutUsBannersState {
  data: PaginatedAboutUsBanner[];
  loading: boolean;
  error: string | null;
}

export interface ListAboutUsBannersRequest {
  query: Record<string, any>;
  onSuccess?(): void;
  onFailure?(): void;
}

interface SuccessAction {
  data: PaginatedAboutUsBanner[];
}

interface FailureAction {
  error: string;
}

const { Types, Creators } = createActions(
  {
    request: ["query", "onSuccess", "onFailure"],
    success: ["data"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "LIST_ABOUT_US_BANNERS_" }
);

const INITIAL_STATE: ListAboutUsBannersState = {
  data: [],
  loading: false,
  error: null,
};

const request = (state: ListAboutUsBannersState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: ListAboutUsBannersState, action: SuccessAction) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });

const failure = (state: ListAboutUsBannersState, action: FailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const listAboutUsBanners = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const ListAboutUsBannersTypes = Types;
export const ListAboutUsBannersActions = Creators;
