import React, { useCallback, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import { PaginatedBuilding } from "contracts/buildings";
import BottomBar from "./BottomBar";
import * as S from "./styles";

type Props = {
  buildings: PaginatedBuilding[];
  loading: boolean;
};

const sliderSettings: Settings = {
  slidesToShow: 1,
  infinite: true,
  autoplay: false,
  autoplaySpeed: 5000,
  adaptiveHeight: true,
  fade: false,
  pauseOnHover: true,
  centerMode: false,
  responsive: [
    {
      breakpoint: 1251,
      settings: {
        centerMode: false,
      },
    },
  ],
};

export const BuildingsCarousel: React.FC<Props> = ({ buildings, loading }) => {
  const sliderRef = useRef<Slider | null>(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  const handlePrevSlide = useCallback((): void => {
    return sliderRef?.current?.slickPrev();
  }, []);

  const handleNextSlide = useCallback((): void => {
    return sliderRef?.current?.slickNext();
  }, []);

  const handleAfterSlideChange = useCallback((currentSlide: number): void => {
    setActiveSlideIndex(currentSlide);
  }, []);

  interface IFeaturedContentComponent {
    activeBuilding: PaginatedBuilding | undefined;
  }

  const FeaturedContentComponent = useCallback(
    ({ activeBuilding }: IFeaturedContentComponent): JSX.Element => {
      if (!activeBuilding) return <></>;

      const {
        name,
        address_street,
        address_number,
        address_neighborhood,
        address_city,
        address_state,
        buildingType,
      } = activeBuilding;

      const locale = `${address_street}, ${address_number}, ${address_neighborhood}, ${address_city} - ${address_state}`;

      return (
        <S.FeaturedContentContainer>
          <S.FeaturedContent>
            <S.BuildingType>{buildingType.name}</S.BuildingType>
            <S.Title>{name}</S.Title>
            <S.Subtitle>
              {address_neighborhood}, {address_city}
            </S.Subtitle>
            <S.BuildingLocale>{locale}</S.BuildingLocale>
          </S.FeaturedContent>
        </S.FeaturedContentContainer>
      );
    },
    []
  );

  return (
    <S.MainContainer>
      <Slider
        ref={sliderRef}
        {...sliderSettings}
        afterChange={handleAfterSlideChange}
      >
        {buildings.map((b, i) => (
          <S.Slide key={i} bgImage={b?.headerImageFilepath}>
            <FeaturedContentComponent activeBuilding={b} />
          </S.Slide>
        ))}
      </Slider>
      <S.Controls>
        <S.PrevButton onClick={handlePrevSlide}>
          <S.PrevIcon />
        </S.PrevButton>
        <S.NextButton onClick={handleNextSlide}>
          <S.NextIcon />
        </S.NextButton>
      </S.Controls>
      <S.BottomBarContainer>
        <BottomBar building={buildings[activeSlideIndex]} />
      </S.BottomBarContainer>
    </S.MainContainer>
  );
};
