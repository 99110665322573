import React from "react";
import { useHistory } from "react-router-dom";
import { PaginatedBuilding } from "contracts/buildings";
import * as S from "./styles";

type Props = {
  building?: PaginatedBuilding;
};

const BottomBar: React.FC<Props> = ({ building }) => {
  const history = useHistory();

  if (!building) return <></>;

  const { size, lofts, bedrooms, parking_lots, sales_percentage } = building;

  const forSale = sales_percentage < 100;
  const stateLabel = forSale ? "Vendas disponíveis" : "Vendas concluídas";
  const stateIcon = forSale ? <S.HomeIcon /> : <S.LikeIcon />;

  return (
    <S.MainContainer>
      <S.StatusBox>
        {stateIcon}
        {stateLabel}
      </S.StatusBox>
      <S.BoxContent>
        {size && (
          <S.BoxItem>
            <S.HouseIcon /> {size}
          </S.BoxItem>
        )}
        {lofts && (
          <S.BoxItem>
            <S.LoftIcon /> {lofts}
          </S.BoxItem>
        )}
        {parking_lots && (
          <S.BoxItem>
            <S.GarageIcon /> {parking_lots}
          </S.BoxItem>
        )}
        {bedrooms && (
          <S.BoxItem>
            <S.BedIcon /> {bedrooms}
          </S.BoxItem>
        )}
      </S.BoxContent>
      <S.NavigateToBuildingButton
        onClick={() => history.push(`/empreendimento/${building?.id}`)}
      >
        <S.TriangleIcon /> Ver empreendimento
      </S.NavigateToBuildingButton>
    </S.MainContainer>
  );
};

export default BottomBar;
