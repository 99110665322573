import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { Scaffold } from "components/Shared";
import { BuildingsCarousel } from "components/Home";
import {
  PaginateBuildingsForSaleActions as PaginateActions,
  PaginateBuildingsForSaleState as PaginateState,
} from "store/ducks/buildings";
import * as S from "./styles";

// The client asked for removing the main content.
// I will not render it instead.
const showMainContent = false;

export const Home: React.FC = () => {
  const dispatch = useDispatch();

  const { loading, data: buildings } = useSelector<
    RootStateOrAny,
    PaginateState
  >((state) => state.paginateBuildingsForSale);

  const fetchBuildings = useCallback((): void => {
    dispatch(PaginateActions.request());
  }, [dispatch]);

  useEffect(() => {
    fetchBuildings();
  }, [fetchBuildings]);

  useEffect(() => {
    return () => {
      dispatch(PaginateActions.reset());
    };
  }, [dispatch]);

  return (
    <Scaffold background={true}>
      <BuildingsCarousel buildings={buildings} loading={loading} />
      {showMainContent && (
        <S.ContentContainer>
          <S.Content>
            <S.Title>
              Nosso plano é<br /> fazer você <span>viver bem</span>.
            </S.Title>
            <S.ItemsWrapper>
              <S.Item>
                <S.ItemTitle>Alto padrão</S.ItemTitle>
                <S.ItemText>
                  Nossos projetos são apoiados na meticulosa escolha do local,
                  na fina concepção dos projetos arquitetônicos, na engenharia
                  eficiente e sustentável e na absoluta solidez financeira.
                </S.ItemText>
              </S.Item>
              <S.Item>
                <S.ItemTitle>Transparência</S.ItemTitle>
                <S.ItemText>
                  Acompanhe cada etapa do empreendimento com o máximo de
                  transparência e clareza. Colocamos você em primeiro lugar para
                  que suas aquisições sejam realizadas com solidez e sem
                  surpresas.
                </S.ItemText>
              </S.Item>
              <S.Item>
                <S.ItemTitle>Qualidade de vida</S.ItemTitle>
                <S.ItemText>
                  Empreendimentos para você usufruir a vida em um espaço único,
                  projetado em cada detalhe com materiais de primeira linha,
                  inovadores, com qualidade construtiva, acabamento diferenciado
                  e áreas comuns equipadas e decoradas.
                </S.ItemText>
              </S.Item>
            </S.ItemsWrapper>
          </S.Content>
        </S.ContentContainer>
      )}
    </Scaffold>
  );
};
