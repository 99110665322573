import styled from "styled-components";
import bg from "assets/images/banner-about.jpg";
import { colors, fonts } from "styles";

export const Container = styled.section`
  width: 100%;
  margin: 0 auto;
  display: flex;
`;

export const Content = styled.div`
  height: 660px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:first-child {
    width: 60%;
    background: ${colors.dark} url(${bg}) no-repeat center center;
    background-size: cover;
  }

  &:last-child {
    width: 40%;
    background-color: ${colors.dark};
    padding: 48px 32px;
  }

  @media screen and (max-width: 1280px) {
    &:first-child {
      width: 50%;
    }
    &:last-child {
      width: 50%;
    }
  }

  @media screen and (max-width: 920px) {
    &:first-child {
      display: none;
    }
    &:last-child {
      width: 100%;
    }
  }

  @media screen and (max-width: 414px) {
    height: 500px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Subtitle = styled.h6`
  font-size: 14px;
  font-family: ${fonts.Muli};
  font-weight: 700;
  text-transform: uppercase;
  color: ${colors.orange};
  margin-bottom: 16px;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 56px;
  line-height: 56px;
  color: white;
  margin-bottom: 24px;
  text-align: center;

  @media screen and (max-width: 414px) {
    font-size: 40px;
    line-height: 40px;
  }
`;

export const Text = styled.article`
  font-size: 18px;
  line-height: 20px;
  color: white;
  text-align: center;
  width: 80%;

  @media screen and (max-width: 414px) {
    font-size: 16px;
    line-height: 18px;
    width: 100%;
  }
`;
