import React from "react";
import { Switch, Route } from "react-router-dom";

import { Home } from "pages/Home";
import { About } from "pages/About";
import { Portfolio } from "pages/Portfolio";
import { Building } from "pages/Building";
import { FollowTheWork } from "pages/FollowTheWork";

export const Routes: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/sobre-nos" exact component={About} />
        <Route path="/portfolio" exact component={Portfolio} />
        <Route path="/acompanhe-sua-obra" exact component={FollowTheWork} />
        <Route path="/empreendimento/:buildingId" exact component={Building} />
      </Switch>
    </>
  );
};
