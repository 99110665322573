import styled from "styled-components";

import BgImg from "assets/images/steps-info-bg.png";
import PlanningImg from "assets/images/steps/color/planning.svg";
import LandworkImg from "assets/images/steps/color/landwork.svg";
import FoundationsImg from "assets/images/steps/color/foundations.svg";
import StructureImg from "assets/images/steps/color/structure.svg";
import BrickworkImg from "assets/images/steps/color/brickwork.svg";
import InstallationsImg from "assets/images/steps/color/installations.svg";
import FacadeImg from "assets/images/steps/color/facade.svg";
import FinishingImg from "assets/images/steps/color/finishing.svg";

import { fonts } from "styles";

export const MainContainer = styled.section`
  background-color: #f2f2f2;
  background-image: url("${BgImg}");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 900px;

  @media screen and (max-width: 1000px) {
    background-size: 90%;
  }
`;

// header

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 72px auto;
  padding: 64px 32px 56px 32px;
  width: calc(100% - 64px);
  max-width: 864px;
  text-align: center;
  border-bottom: 1px solid #afb2b6;
`;

export const HeaderTitle = styled.h4`
  font-family: ${fonts.NexaBold};
  font-size: 36px;
  margin-bottom: 24px;
`;

export const HeaderSubtitle = styled.p`
  max-width: 320px;
  font-size: 15px;
`;

// main
export const MainContent = styled.div`
  width: 100%;
  max-width: 1270px;
  padding: 32px;
  margin: 0 auto;
  padding-bottom: 400px;
  columns: 3;
  column-gap: 64px;

  @media screen and (max-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  /* this place reeks of magic */
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid-column;
  display: table;

  @media screen and (min-width: 1001px) {
    &:nth-child(5) {
      margin-bottom: 100%;
    }
  }
`;

export const StepIcon = styled.div``;

export const StepTitle = styled.h4`
  margin: 18px 0px;
  font-size: 20px;
`;

export const StepDescription = styled.p`
  -webkit-column-break-inside: inherit;
  page-break-inside: inherit;
  break-inside: inherit;
  display: inherit;
  font-size: 15px;
  line-height: 1.4em;
`;

const ImageIcon = styled.img`
  max-width: 40px;
  max-height: 40px;
  height: auto;
`;

export const PlanningIcon = styled(ImageIcon).attrs({
  src: PlanningImg,
})``;
export const LandworkIcon = styled(ImageIcon).attrs({
  src: LandworkImg,
})``;
export const FoundationsIcon = styled(ImageIcon).attrs({
  src: FoundationsImg,
})``;
export const StructureIcon = styled(ImageIcon).attrs({
  src: StructureImg,
})``;
export const BrickworkIIcon = styled(ImageIcon).attrs({
  src: BrickworkImg,
})``;
export const InstallationsIcon = styled(ImageIcon).attrs({
  src: InstallationsImg,
})``;
export const FacadeIcon = styled(ImageIcon).attrs({
  src: FacadeImg,
})``;
export const FinishingIcon = styled(ImageIcon).attrs({
  src: FinishingImg,
})``;
