import styled from "styled-components";
import FeaturesImg from "assets/images/features.png";
import ProximityImg from "assets/images/proximity.png";
import { colors, fonts } from "styles";

export const MainContainer = styled.section`
  background-color: ${colors.dark};
  padding: 32px 0px;
  @media screen and (max-width: 890px) {
    padding-left: 32px;
    padding-right: 32px;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-width: 840px;
  column-gap: 40px;
  margin-top: -160px;

  @media screen and (max-width: 890px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  border-top: 10px solid ${colors.blue};
  padding: 40px 60px;
  background-color: #fff;
  flex: 1 1 50%;
  position: relative;
  box-shadow: 0px 0px 20px #0001;
  p {
    /* Thou shall abide by my rules 🧙‍♂ */
    white-space: pre-wrap;
    line-height: 1.4rem;
  }
  @media screen and (max-width: 890px) {
    padding: 40px 32px;
    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }
`;

export const Title = styled.h4`
  font-family: ${fonts.NexaBold};
  font-size: 26px;
  margin-bottom: 18px;
`;

export const Features = styled(Content)``;

export const Proximity = styled(Content)``;

const FloatingIcon = styled.img`
  position: absolute;
  max-width: 100px;
  @media screen and (max-width: 890px) {
    max-width: 84px;
    top: -48px !important;
    left: 50% !important;
    right: auto !important;
    bottom: auto !important;
    transform: translateX(-50%);
  }
`;

export const FeaturesIcon = styled(FloatingIcon).attrs({
  src: `${FeaturesImg}`,
})`
  top: 80px;
  left: -60px;
`;

export const ProximityIcon = styled(FloatingIcon).attrs({
  src: `${ProximityImg}`,
})`
  bottom: 120px;
  right: -60px;
`;
