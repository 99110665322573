import React from "react";
import { useHistory } from "react-router";

import * as S from "./styles";

export const BuildingsForSale: React.FC = () => {
  const history = useHistory();

  return (
    <S.Container>
      <S.SoldTitle>Veja também nossos empreendimentos à venda</S.SoldTitle>
      <S.SoldText>
        Invista você também nos seus sonhos e adquira seu imóvel em um dos
        nossos empreendimentos!
      </S.SoldText>
      <S.Button btStyle="secondary" onClick={() => history.push("/")}>
        Ver Empreendimentos à venda
      </S.Button>
    </S.Container>
  );
};
