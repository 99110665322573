import styled from "styled-components";
import { Like, HomeAlt } from "@styled-icons/boxicons-regular";

import defaultImageContentBg from "assets/images/portfolio-example.png";
import iconGarage from "assets/images/icon-garage-black.png";
import iconhouse from "assets/images/icon-house-plan-black.png";
import iconLoft from "assets/images/icon-loft-plan-black.png";
import iconBed from "assets/images/icon-bed-black.png";

import { colors, fonts, colorScheme } from "styles";

export const Container = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
`;

const Content = styled.div<{ url?: string }>`
  height: 660px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// image content

interface IImageContent {
  bgImage: string | null;
}

export const ImageContent = styled(Content)<IImageContent>`
  width: 60%;
  background-color: ${colors.dark};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${({ bgImage }) =>
    `url(${bgImage || defaultImageContentBg})`};

  @media screen and (max-width: 1000px) {
    width: 50%;
  }

  @media screen and (max-width: 780px) {
    display: none;
  }
`;

// main content

export const MainContent = styled(Content)`
  width: 40%;
  background-color: ${colors.dark};

  @media screen and (max-width: 1000px) {
    width: 50%;
  }

  @media screen and (max-width: 780px) {
    width: 100%;
  }

  @media screen and (max-width: 640px) {
    height: auto;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;

  @media screen and (max-width: 640px) {
    padding: 48px 32px;
  }
`;

export const Subtitle = styled.h6`
  font-size: 14px;
  font-family: ${fonts.Muli};
  font-weight: 700;
  text-transform: uppercase;
  color: ${colors.orange};
  margin-bottom: 16px;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 56px;
  line-height: 56px;
  color: white;
  text-align: center;

  @media screen and (max-width: 414px) {
    font-size: 40px;
    line-height: 40px;
  }
`;

export const City = styled.h6`
  font-family: ${fonts.Muli};
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 24px;
  text-transform: uppercase;
  color: white;
`;

export const Address = styled.article`
  font-size: 15px;
  line-height: 20px;
  color: white;
  text-align: center;
  width: 80%;
  padding-bottom: 40px;
  border-bottom: 1px #535353 solid;

  @media screen and (max-width: 414px) {
    font-size: 16px;
    line-height: 18px;
    width: 100%;
  }
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
  margin-top: 40px;
`;

// footer

export const BarBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  height: 100px;
  border-left: 4px ${colors.orange} solid;
  position: absolute;
  bottom: 0;

  @media screen and (max-width: 1250px) {
    flex-direction: column-reverse;
    height: auto;
    border-left: 0;
    align-items: center;
  }

  @media screen and (max-width: 640px) {
    position: relative;
    bottom: auto;
  }
`;

export const BoxStatus = styled.div`
  width: 96px;
  height: 100%;
  padding: 16px;
  background-color: ${colors.blueGray};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 11px;
  line-height: 14px;
  font-family: ${fonts.NexaBold};
  text-align: center;
  text-transform: uppercase;
  color: white;

  @media screen and (max-width: 1250px) {
    display: none;
  }
`;

export const BoxContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 32px;
  background-color: white;

  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: max-content;
  }
`;

export const IconLike = styled(Like).attrs({ size: 32 })`
  margin-right: 8px;
  color: ${colorScheme.secondary};
`;

export const IconLikeBar = styled(Like).attrs({ size: 28 })`
  margin-bottom: 8px;
  color: white;
`;

export const BoxItem = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  max-width: 230px;
  font-size: 14px;
  font-weight: 700;
  padding: 0 24px;

  @media screen and (max-width: 1280px) {
    padding: 0 16px;
    flex: 1;
  }

  @media screen and (max-width: 640px) {
    padding: 16px;
    max-width: 100%;
  }
`;

export const IconGarage = styled.img.attrs({ src: iconGarage })`
  margin-right: 8px;
`;

export const Iconhouse = styled.img.attrs({ src: iconhouse })`
  margin-right: 8px;
`;

export const IconLoft = styled.img.attrs({ src: iconLoft })`
  margin-right: 8px;
`;

export const IconBed = styled.img.attrs({ src: iconBed })`
  margin-right: 8px;
`;

export const ButtonSale = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  background-color: ${colors.blue};
  padding: 32px;
  width: 100%;
  max-width: 280px;

  font-family: ${fonts.Muli};
  font-size: 15px;
  color: white;
  text-align: left;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
  }

  span {
    color: ${colors.orange};
    font-weight: 700;
    text-decoration: underline;
  }

  @media screen and (max-width: 1250px) {
    margin-bottom: 32px;
    padding: 16px;
    border-radius: 6px;
    max-width: max-content;
  }

  @media screen and (max-width: 640px) {
    margin-bottom: 0;
    border-radius: 0;
    max-width: 100%;
    justify-content: center;

    div {
      max-width: max-content;
    }
  }
`;

export const IconHome = styled(HomeAlt).attrs({ size: 28 })`
  color: white;
  margin-right: 16px;
`;
