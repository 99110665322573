import styled from "styled-components";
import { colors, fonts } from "styles";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
`;

export const Section = styled.section`
  display: flex;

  @media screen and (min-width: 1101px) {
    flex-direction: row;
    min-height: 650px;
    > div.image-container {
      order: 1;
      flex: 1 0 60%;
    }
    > div.text-container {
      order: 2;
      flex: 1 0 40%;
    }

    &:nth-child(even) {
      > div.image-container {
        order: 2;
        flex: 1 0 40%;
      }
      > div.text-container {
        order: 1;
        flex: 1 0 60%;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    > div.image-container {
      order: 1;
      flex: 0 1 320px;
      &:only-child {
        flex: 0 0 320px;
      }
    }
    > div.text-container {
      order: 2;
      flex: 1 0 auto;
      padding: 64px 24px;
    }
  }
`;

interface IImageContainer {
  image: string;
}

export const ImageContainer = styled.div.attrs({
  className: "image-container",
})<IImageContainer>`
  background-image: url(${(props) => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

// text

export const TextContainer = styled.div.attrs({ className: "text-container" })`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 24px;
  background-color: ${colors.dark};
  padding: 48px 32px;
`;

export const Title = styled.h1`
  font-size: 56px;
  line-height: 56px;
  color: white;
  @media screen and (max-width: 414px) {
    font-size: 40px;
    line-height: 40px;
  }
`;

export const Description = styled.article`
  font-size: 18px;
  line-height: 20px;
  color: white;
  width: 80%;
  @media screen and (max-width: 414px) {
    font-size: 16px;
    line-height: 18px;
    width: 100%;
  }
`;
