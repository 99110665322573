import styled from "styled-components";
import { LocationMarker } from "@styled-icons/heroicons-outline/LocationMarker";

import { colors, fonts } from "styles";
export { ActivityIndicator } from "styles/styled-components";

export const MainContainer = styled.div``;

export const InnerHeader = styled.div`
  padding: 62px 32px 72px 32px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
`;

export const InnerHeaderTitle = styled.h4`
  font-family: ${fonts.NexaBold};
  font-size: 36px;
  margin-bottom: 14px;
`;

export const InnerHeaderSubtitle = styled.div`
  font-size: 18px;
  line-height: 18px;
  color: #4a525b;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MarkerIcon = styled(LocationMarker).attrs({
  size: 18,
  color: colors.orange,
})`
  margin-right: 14px;
`;
