import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { PaginatedAboutUsBanner } from "contracts/aboutUsBanners";
import {
  ListAboutUsBannersActions as ListActions,
  ListAboutUsBannersState as ListState,
} from "store/ducks/aboutUsBanners";
import * as S from "./styles";

interface ISection {
  banner: PaginatedAboutUsBanner;
}

export const AboutUsSections: React.FC = () => {
  const dispatch = useDispatch();
  const { data: banners, loading } = useSelector<RootStateOrAny, ListState>(
    (state) => state.listAboutUsBanners
  );

  const fetchBanners = useCallback(() => {
    dispatch(ListActions.request({ orderBy: "order", direction: "asc" }));
  }, [dispatch]);

  const Section = useCallback(({ banner }: ISection): JSX.Element => {
    const { imagepath, title, description } = banner;

    const TextContainer: JSX.Element = (
      <S.TextContainer>
        {title && <S.Title>{title}</S.Title>}
        {description && <S.Description>{description}</S.Description>}
      </S.TextContainer>
    );

    return (
      <S.Section>
        {imagepath && <S.ImageContainer image={imagepath} />}
        {title || description ? TextContainer : null}
      </S.Section>
    );
  }, []);

  useEffect(() => {
    fetchBanners();
  }, [fetchBanners]);

  useEffect(() => {
    return () => {
      dispatch(ListActions.reset());
    };
  }, [dispatch]);

  return (
    <S.MainContainer>
      {banners.map((banner) => (
        <Section key={banner.id} banner={banner} />
      ))}
    </S.MainContainer>
  );
};
