import React from "react";
import Slider, { Settings } from "react-slick";
import { Plan } from "contracts/buildings";
import * as S from "./styles";

type Props = {
  plans: Plan[];
};

const sliderSettings: Settings = {
  slidesToShow: 1,
  speed: 500,
  nextArrow: (
    <S.NextArrow>
      <S.RightIcon />
    </S.NextArrow>
  ),
  prevArrow: (
    <S.PrevArrow>
      <S.LeftIcon />
    </S.PrevArrow>
  ),

  responsive: [
    {
      breakpoint: 501,
      settings: {
        dots: true,
      },
    },
  ],
};

export const PlansGallery: React.FC<Props> = ({ plans = [] }) => {
  return (
    <S.MainContainer>
      <S.Header>
        <S.Title>Opções de plantas</S.Title>
        <S.Subtitle>
          <p>Estruturas de acordo com sua necessidade</p>
        </S.Subtitle>
      </S.Header>
      <S.Gallery>
        <Slider {...sliderSettings}>
          {plans.map((image) => (
            <S.Slide
              key={image.id}
              imageSrc={image.filepath}
              title={image.description || ""}
            />
          ))}
        </Slider>
      </S.Gallery>
      <S.Disclaimer>
        <p>
          * Plantas ilustrativas com sugestão de decoração. Os móveis, assim
          como alguns materiais de acabamento representados na planta, são de
          dimensões comercias e não fazem parte do contrato. O imóvel será
          entregue como indicado no memorial descritivo.
        </p>
      </S.Disclaimer>
    </S.MainContainer>
  );
};
