import styled, { css } from "styled-components";
import logo from "assets/images/logo.png";

type ContainerContract = {
  transform?: "yes" | "no";
};

export const Container = styled.header<ContainerContract>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  position: fixed;
  top: 0;
  z-index: 999;
  transition: 300ms ease;

  ${({ transform }) =>
    transform &&
    css`
      background-color: white;
      position: sticky;
    `}

  @media screen and (max-width: 920px) {
    background-color: white;
  }
`;

type LogoContract = {
  transform?: "yes" | "no";
};

export const Logo = styled.img.attrs({ src: logo })<LogoContract>`
  width: 100%;
  max-width: max-content;
  transition: 300ms ease;

  ${({ transform }) =>
    !transform &&
    css`
      filter: brightness(0) invert(1);
    `}

  @media screen and (max-width: 920px) {
    filter: unset;
  }

  @media screen and (max-width: 414px) {
    width: 80%;
  }
`;
