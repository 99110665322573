import { all, takeLatest } from "redux-saga/effects";

// Types
import { ListAboutUsBannersTypes } from "store/ducks/aboutUsBanners";
import {
  FetchBuildingTypes,
  PaginateBuildingsForSaleTypes,
  PaginateBuildingsSoldTypes,
  PaginateBuildingsTypes,
} from "store/ducks/buildings";
import {
  ListPortfolioBuildingsTypes,
  PaginatePortfolioBuildingsTypes,
} from "store/ducks/portfolioBuildings";

// Sagas
import { listAboutUsBannersRequest } from "./aboutUsBanners";
import {
  fetchBuildingRequest,
  paginateBuildingsForSaleRequest,
  paginateBuildingsRequest,
  paginateBuildingsSoldRequest,
} from "./buildings";
import {
  listPortfolioBuildingsRequest,
  paginatePortfolioBuildingsRequest,
} from "./portfolioBuildings";

export default function* rootSaga() {
  yield all([
    // about us banners
    takeLatest(ListAboutUsBannersTypes.REQUEST, listAboutUsBannersRequest),
    // buildings
    takeLatest(FetchBuildingTypes.REQUEST, fetchBuildingRequest),
    takeLatest(
      PaginateBuildingsForSaleTypes.REQUEST,
      paginateBuildingsForSaleRequest
    ),
    takeLatest(PaginateBuildingsTypes.REQUEST, paginateBuildingsRequest),
    takeLatest(
      PaginateBuildingsSoldTypes.REQUEST,
      paginateBuildingsSoldRequest
    ),
    // portfolio buildings
    takeLatest(
      ListPortfolioBuildingsTypes.REQUEST,
      listPortfolioBuildingsRequest
    ),
    takeLatest(
      PaginatePortfolioBuildingsTypes.REQUEST,
      paginatePortfolioBuildingsRequest
    ),
  ]);
}
