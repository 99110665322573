import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { colorScheme, colors } from "styles";

type ButtonStyleOption =
  | "primary"
  | "secondary"
  | "transparent"
  | "light"
  | "outline"
  | "outlineLight"
  | "outlineDark"
  | "cancel"
  | "danger";

interface IButton {
  btStyle?: ButtonStyleOption;
}

const styleButton = {
  primary: css`
    background-color: ${colorScheme.primary};
    color: white;
  `,
  secondary: css`
    background-color: ${colorScheme.secondary};
    color: white;
  `,
  outline: css`
    background-color: transparent;
    color: ${colorScheme.primary};
    border: 2px ${colorScheme.primary} solid;
  `,
  outlineLight: css`
    background-color: transparent;
    color: white;
    border: 2px white solid;
    border-radius: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    height: auto;

    :hover {
      background-color: white;
      color: ${colors.dark};
    }
  `,
  outlineDark: css`
    background-color: transparent;
    color: ${colors.dark};
    border: 2px ${colors.dark} solid;
    border-radius: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    height: auto;
    :hover {
      background-color: ${colors.dark};
      color: white;
    }
  `,
  transparent: css`
    background-color: transparent;
    color: ${colorScheme.primary};
    padding: 16px 0;
  `,
  light: css`
    background-color: white;
    color: ${colorScheme.secondary};
  `,
  cancel: css`
    background-color: #e0e0e0;
    color: #888;
  `,
  danger: css`
    background-color: #ff2e4a;
    color: white;
  `,
};

export const Button = styled.button<IButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 16px 24px;
  height: 56px;
  transition: 300ms ease;

  &:hover {
    transform: scale(0.95);
  }

  ${({ btStyle }) => (btStyle ? styleButton[btStyle] : styleButton.primary)}
`;

export const LinkButton = styled(Link)<IButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 16px 24px;
  height: 56px;
  transition: 300ms ease;

  &:hover {
    transform: scale(0.95);
  }

  ${({ btStyle }) => (btStyle ? styleButton[btStyle] : styleButton.primary)}
`;

export const ButtonMini = styled.button<IButton>`
  display: flex;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  transition: 300ms ease;

  :hover {
    transform: scale(0.95);
  }

  ${({ btStyle }) => (btStyle ? styleButton[btStyle] : styleButton.primary)}
`;
