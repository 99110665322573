import styled, { css } from "styled-components";
import { HomeAlt } from "@styled-icons/boxicons-regular";
import { Whatsapp } from "@styled-icons/boxicons-logos";
import { TriangleRight } from "@styled-icons/entypo";

import DefaultImage from "assets/images/default-header-image.jpg";
import { ReactComponent as Building } from "assets/images/icon-building.svg";
import { ActivityIndicator } from "styles/styled-components";
import { colors, fonts } from "styles";

export const Container = styled.section`
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;

  .slick-prev:before,
  .slick-next:before {
    content: "";
    display: none;
  }
  position: relative;
`;

interface ISlide {
  bgImage: string | null;
}

export const Slide = styled.div<ISlide>`
  width: 100%;
  height: 100vh;
  background-color: #ccc;
  background-image: ${({ bgImage }) => `url('${bgImage || DefaultImage}')`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TextWrapper = styled.div`
  width: 100%;
  max-width: 684px;
  padding-left: 214px;
  align-self: flex-start;
  justify-self: center;

  @media screen and (max-width: 900px) {
    padding: 32px;
    align-self: center;
  }
`;

export const Title = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 44px;
  line-height: 44px;
  color: white;
  margin-bottom: 16px;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 190px;
    height: 2px;
    position: absolute;
    left: -214px;
    background-color: ${colors.orange};
    z-index: 1;
  }

  @media screen and (max-width: 900px) {
    text-align: center;

    &:before {
      display: none;
    }
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

export const Text = styled.article`
  color: white;
  font-size: 16px;
  line-height: 24px;

  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

export const BarBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  height: 100px;
  border-left: 4px ${colors.orange} solid;

  @media screen and (max-width: 900px) {
    flex-direction: column-reverse;
    height: auto;
    border-left: 0;
    align-items: center;
  }
`;

export const BoxStatus = styled.div`
  width: 96px;
  height: 100%;
  padding: 24px 16px;
  background-color: ${colors.blueGray};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 11px;
  font-family: ${fonts.NexaBold};
  text-align: center;
  text-transform: uppercase;
  color: white;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const HomeIcon = styled(HomeAlt).attrs({ size: 18 })`
  color: white;
  margin-bottom: 8px;
`;

export const BuildingIcon = styled(Building)`
  color: white;
  margin-right: 16px;
`;

export const BoxContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  background-color: white;

  @media screen and (max-width: 640px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const BoxTitle = styled.h5`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${colors.blueGray};
  height: 100%;

  @media screen and (max-width: 900px) {
    font-size: 14px;
  }
`;

export const ButtonBuildingView = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  padding: 0 40px;
  margin-left: 40px;
  border-left: 1px #cbcbcb solid;
  height: 100%;

  font-size: 11px;
  font-family: ${fonts.Muli};
  font-weight: 600;
  color: ${colors.blueGray};
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    svg {
      transform: translateX(4px);
    }
  }

  @media screen and (max-width: 640px) {
    border-left: 0;
    padding: 24px 0 0 0;
    margin-left: 0;
  }
`;

export const TriangleIcon = styled(TriangleRight).attrs({ size: 18 })`
  color: ${colors.orange};
  margin-right: 8px;
  transition: 300ms ease;
`;

export const ButtonContact = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  background-color: ${colors.blue};
  padding: 32px;
  width: 100%;
  max-width: max-content;

  font-family: ${fonts.Muli};
  font-size: 16px;
  color: white;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
  }

  a {
    color: ${colors.white};
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;
  }

  &:hover {
    svg {
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: 900px) {
    margin-bottom: 32px;
    padding: 16px;
    border-radius: 6px;
  }
`;

export const WhatsappIcon = styled(Whatsapp).attrs({ size: 32 })`
  color: ${colors.orange};
  margin-right: 8px;
  transition: 300ms ease;
`;

export const Controls = styled.div`
  position: absolute;
  right: 32px;
  bottom: 120px;
  z-index: 2;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const Dot = styled.button.attrs({ type: "button" })<{ active: boolean }>`
  width: 30px;
  height: 2px;
  background-color: #ffffff80;
  margin-right: 8px;
  transition: 300ms ease;

  &:last-child {
    margin-right: 0;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: white;
      height: 4px;
    `}
`;

export const Loading = styled(ActivityIndicator)`
  color: black;
  align-self: center;
  justify-content: center;
`;
