import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {
  Header,
  CompletionSteps,
  StepsInfo,
  MilestoneGallery,
} from "components/FollowTheWork";
import { Scaffold, BuildingsForSale } from "components/Shared";
import {
  PaginateBuildingsActions as PaginateActions,
  PaginateBuildingsState as PaginateState,
  FetchBuildingActions as FetchActions,
  FetchBuildingState as FetchState,
} from "store/ducks/buildings";
import * as S from "./styles";

export const FollowTheWork: React.FC = () => {
  const dispatch = useDispatch();

  const { data: buildings, loading: loadingBuildings } = useSelector<
    RootStateOrAny,
    PaginateState
  >((state) => state.paginateBuildings);

  const { data: building, loading: loadingBuilding } = useSelector<
    RootStateOrAny,
    FetchState
  >((state) => state.fetchBuilding);

  const InnerHeaderComponent: React.FC = useCallback(() => {
    if (loadingBuilding) {
      return (
        <S.InnerHeader>
          <S.ActivityIndicator />
        </S.InnerHeader>
      );
    }

    if (!building) return <></>;

    return (
      <S.InnerHeader>
        <S.InnerHeaderTitle>{building.name}</S.InnerHeaderTitle>
        <S.InnerHeaderSubtitle>
          <S.MarkerIcon />
          <span>
            {building.address_neighborhood}, {building.address_city}
          </span>
        </S.InnerHeaderSubtitle>
      </S.InnerHeader>
    );
  }, [building, loadingBuilding]);

  const NoBuildingSelectedComponent: React.FC = useCallback(() => {
    if (building || loadingBuilding) {
      return <></>;
    }
    return (
      <S.InnerHeader>
        <S.InnerHeaderTitle>
          Nenhum empreendimento selecionado
        </S.InnerHeaderTitle>
        <S.InnerHeaderSubtitle>
          <span>
            Selecione um empreendimento de seu interesse na lista acima
          </span>
        </S.InnerHeaderSubtitle>
      </S.InnerHeader>
    );
  }, [building, loadingBuilding]);

  const fetchBuildings = useCallback((): void => {
    dispatch(PaginateActions.request({ limit: 99 }));
  }, [dispatch]);

  const fetchBuilding = useCallback(
    (buildingId: number): void => {
      dispatch(FetchActions.request(buildingId));
    },
    [dispatch]
  );

  const handleOnBuildingSelected = useCallback(
    (buildingId: number) => {
      fetchBuilding(buildingId);
    },
    [fetchBuilding]
  );

  useEffect(() => {
    fetchBuildings();
  }, [fetchBuildings]);

  useEffect(() => {
    return () => {
      dispatch(PaginateActions.reset());
      dispatch(FetchActions.reset());
    };
  }, [dispatch]);

  return (
    <Scaffold background>
      <Header
        buildings={buildings}
        isLoading={loadingBuildings}
        onBuildingSelected={handleOnBuildingSelected}
      />
      <NoBuildingSelectedComponent />
      <InnerHeaderComponent />
      {(building && <CompletionSteps building={building} />) || <></>}
      {(building?.milestoneGalleryImages?.length && (
        <MilestoneGallery images={building.milestoneGalleryImages} />
      )) || <></>}
      <StepsInfo />
      <BuildingsForSale />
    </Scaffold>
  );
};
