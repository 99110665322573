import { useState, useEffect, useCallback } from "react";

interface Dimensions {
  width: number;
  height: number;
}

export const useDimensions = () => {
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = useCallback(
    (e: any) => {
      const { innerWidth, innerHeight } = e.target;
      setDimensions((state) => ({
        ...state,
        width: innerWidth,
        height: innerHeight,
      }));
    },
    [setDimensions]
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return dimensions;
};
