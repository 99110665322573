import styled from "styled-components";
import { Whatsapp } from "@styled-icons/bootstrap/Whatsapp";

import BgImg from "assets/images/banner-work.jpg";
import { colors, fonts } from "styles";
export { ActivityIndicator, FormRow } from "styles/styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 670px;
  background-image: url("${BgImg}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const ContentContrainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1070px;
  height: inherit;
  min-height: inherit;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 56px;
  @media screen and (max-width: 960px) {
    padding-bottom: 0px;
  }
`;

// header

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 100%;
  color: #fff;
  padding: 32px;
`;

export const Title = styled.h1`
  font-family: ${fonts.NexaBold};
  line-height: 1em;
  font-size: 84px;
  margin-bottom: 24px;

  @media screen and (max-width: 720px) {
    font-size: 14vw;
  }
`;

export const Subtitle = styled.p`
  font-size: 15px;
  max-width: 280px;
`;

// main and form

export const MainContent = styled.div`
  display: flex;
  width: 100%;
  max-width: inherit;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border-left: 6px solid ${colors.orange};

  form {
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 26px 46px;
    .field-container {
      margin-bottom: 0px;
      label {
        display: none;
      }
    }
  }

  @media screen and (max-width: 960px) {
    bottom: 0;
    border-left: none;
    form {
      width: 100%;
      .field-container label {
        display: initial;
      }
    }
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
`;

export const SelectorLabel = styled.div`
  font-family: ${fonts.Muli};
  font-weight: bold;
  font-size: 20px;
  flex: 1 0 auto;
  margin-right: 32px;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

// contact

export const ContactArea = styled.div`
  background-color: ${colors.blue};
  padding: 24px;
  align-self: stretch;
  color: #fff;
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const WhatsIcon = styled(Whatsapp).attrs({
  size: 24,
  color: colors.orange,
})`
  margin-right: 12px;
`;

export const ContactText = styled.p`
  font-size: 15px;
  span {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4em;
  }
`;
