import { Building } from "contracts/buildings";
import React, { ReactNode, useCallback } from "react";

import * as S from "./styles";

type Props = {
  building: Building;
};

interface ICompletionStep {
  name: string;
  completion: number;
  icon: ReactNode;
}

export const CompletionSteps: React.FC<Props> = ({ building }) => {
  const getCompletionSteps = useCallback((): ICompletionStep[] => {
    const {
      planning_percentage,
      landwork_percentage,
      foundations_percentage,
      structure_percentage,
      brickwork_percentage,
      installations_percentage,
      facade_percentage,
      finishing_percentage,
    } = building;

    return [
      {
        name: "Acabamento",
        completion: finishing_percentage,
        icon: <S.FinishingIcon />,
      },
      {
        name: "Fachada",
        completion: facade_percentage,
        icon: <S.FacadeIcon />,
      },
      {
        name: "Instalações",
        completion: installations_percentage,
        icon: <S.InstallationsIcon />,
      },
      {
        name: "Alvenaria",
        completion: brickwork_percentage,
        icon: <S.BrickworkIIcon />,
      },
      {
        name: "Estrutura",
        completion: structure_percentage,
        icon: <S.StructureIcon />,
      },
      {
        name: "Fundações",
        completion: foundations_percentage,
        icon: <S.FoundationsIcon />,
      },
      {
        name: "Demolição/movimento de terra",
        completion: landwork_percentage,
        icon: <S.LandworkIcon />,
      },
      {
        name: "Projetos complementares",
        completion: planning_percentage,
        icon: <S.PlanningIcon />,
      },
    ];
  }, [building]);

  const HeaderComponent: React.FC = useCallback(() => {
    const completed = getCompletionSteps().filter(
      (step) => step.completion === 100
    ).length;

    return (
      <S.Header>
        <S.Title>Acompanhe as etapas</S.Title>
        <S.Subtitle>{completed} de 8 etapas concluídas.</S.Subtitle>
      </S.Header>
    );
  }, [getCompletionSteps]);

  return (
    <S.MainContainer>
      <S.ImageContent bgImage={building.featuredImageFilepath}>
        <S.SalesPercentage>
          <S.ThumbsUpIcon />
          <span>{building.sales_percentage}%</span>
          <span>VENDIDO!</span>
        </S.SalesPercentage>
      </S.ImageContent>
      <S.MainContent>
        <HeaderComponent />
        {getCompletionSteps().map((step) => (
          <S.Step key={step.name}>
            <S.IconContainer>{step.icon}</S.IconContainer>
            <S.StepContent>
              <S.StepHeader>
                <S.HeaderTitle>{step.name}</S.HeaderTitle>
                <S.HeaderPercentage>
                  {step.completion.toString()}%
                </S.HeaderPercentage>
              </S.StepHeader>
              <S.CompletionBar>
                <S.CompletionLevel completion={step.completion} />
              </S.CompletionBar>
            </S.StepContent>
          </S.Step>
        ))}
      </S.MainContent>
    </S.MainContainer>
  );
};
