import styled, { css } from "styled-components";
import { ThumbsUp } from "@styled-icons/feather/ThumbsUp";
import seloProntoMorar from "assets/images/selo-pronto-morar.png";
import { colors, fonts } from "styles";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
`;

export const InnerContainer = styled.div`
  width: 400px;
  width: 100%;
  height: 500px;
  position: relative;

  img {
    position: relative;
    z-index: 0;
  }

  @media screen and (max-width: 1260px) {
    margin: 0 auto;
  }

  @media screen and (max-width: 414px) {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      max-width: max-content;
      max-height: max-content;
    }
  }

  &:hover {
    .overlay {
      opacity: 0.5;
    }
  }
`;

// stamps area
export const Stamps = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 32px;
  z-index: 3;
`;

export const SalesPercentage = styled.div`
  width: 100px;
  height: 130px;
  background-color: ${colors.blue};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-bottom: 8px;
  font-family: ${fonts.NexaBold};
`;

export const ProntoMorar = styled.img.attrs({ src: seloProntoMorar })`
  width: 100%;
  max-width: max-content;
  max-height: max-content;
  margin-bottom: 8px;
`;

export const ThumbsUpIcon = styled(ThumbsUp).attrs({
  size: 28,
  color: colors.orange,
})`
  stroke-width: 2px;
  margin-bottom: 16px;
`;

// overlay

export const Overlay = styled.div.attrs({ className: "overlay" })`
  width: 100%;
  max-width: 400px;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  transition: 300ms ease;
`;

export const ImageContainer = styled.div`
  width: 100%;
  max-width: 400px;
  height: max-content;
  img {
    width: 100%;
    height: auto;
  }
`;

// details

export const DetailsContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  width: 400px;
  flex-direction: column;
  font-family: ${fonts.Muli};
  font-size: 14px;
  background-color: ${colors.dark};
  color: #fff;
  padding: 40px;
`;

export const BuildingName = styled.h4`
  font-family: ${fonts.NexaBold};
  font-size: 22px;
  margin-bottom: 14px;
  text-transform: uppercase;
`;

export const DetailRow = styled.div`
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const DetailLabel = styled.span`
  font-weight: bold;
`;

// details header

export const DetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -120px;
  margin-bottom: 16px;
  min-height: 100px;
  z-index: 2;
`;

type BuildingLogoContract = {
  bgImage?: string | null;
};

const getLogoCss = (bgImage?: string | null) => {
  if (!bgImage) return css``;
  return css`
    background-image: url(${bgImage});
    background-size: cover;
  `;
};

export const HeaderLogo = styled.div<BuildingLogoContract>`
  width: 100px;
  height: 100px;
  background-color: transparent;
  margin-right: 20px;
  ${({ bgImage }) => getLogoCss(bgImage)};
`;

export const HeaderMainContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  p:not(:last-of-type) {
    margin-bottom: 8px;
  }
  p.strong {
    font-weight: bold;
    text-transform: uppercase;
  }
`;
