import styled from "styled-components";
import { Like, HomeAlt } from "@styled-icons/boxicons-regular";
import { TriangleRight } from "@styled-icons/entypo";

import { colors, fonts } from "styles";
import iconGarage from "assets/images/icon-garage-black.png";
import iconhouse from "assets/images/icon-house-plan-black.png";
import iconLoft from "assets/images/icon-loft-plan-black.png";
import iconBed from "assets/images/icon-bed-black.png";

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  height: 100px;
  border-left: 4px ${colors.orange} solid;
  background-color: #fff;

  @media (max-width: 1250px) {
    flex-direction: column-reverse;
    height: auto;
    align-items: center;
  }
`;

export const StatusBox = styled.div`
  height: 100%;
  padding: 16px;
  background-color: ${colors.blueGray};
  display: flex;
  flex: 0 0 110px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 11px;
  line-height: 14px;
  font-family: ${fonts.NexaBold};
  text-align: center;
  text-transform: uppercase;
  color: white;

  @media (max-width: 1250px) {
    display: none;
  }
`;

export const BoxContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 32px;
  background-color: white;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: max-content;
  }
`;

export const BoxItem = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  max-width: 230px;
  font-size: 14px;
  font-weight: 700;
  padding: 0 24px;

  @media screen and (max-width: 1280px) {
    padding: 0 16px;
    flex: 1;
  }

  @media screen and (max-width: 640px) {
    padding: 16px;
    max-width: 100%;
  }
`;

export const NavigateToBuildingButton = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  padding: 0 40px;
  margin-left: 40px;
  border-left: 1px #cbcbcb solid;
  height: 52px;
  align-self: center;

  font-size: 11px;
  font-family: ${fonts.Muli};
  font-weight: 600;
  color: ${colors.blueGray};
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    svg {
      transform: translateX(4px);
    }
  }

  @media (max-width: 1250px) {
    border-left: 0;
    padding: 24px 0 0 0;
    margin-left: 0;
  }
`;

// icons

export const GarageIcon = styled.img.attrs({ src: iconGarage })`
  margin-right: 8px;
`;

export const HouseIcon = styled.img.attrs({ src: iconhouse })`
  margin-right: 8px;
`;

export const LoftIcon = styled.img.attrs({ src: iconLoft })`
  margin-right: 8px;
`;

export const BedIcon = styled.img.attrs({ src: iconBed })`
  margin-right: 8px;
`;

export const HomeIcon = styled(HomeAlt).attrs({ size: 28 })`
  color: white;
  margin-bottom: 10px;
`;

export const LikeIcon = styled(Like).attrs({ size: 28 })`
  color: white;
  margin-bottom: 10px;
`;

export const TriangleIcon = styled(TriangleRight).attrs({ size: 18 })`
  color: ${colors.orange};
  margin-right: 8px;
  transition: 300ms ease;
`;
