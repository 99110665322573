import { call, put } from "redux-saga/effects";
import { api, notify } from "services";
import { requestErrorHandler, applyQueryParams } from "utils";
import {
  PaginatePortfolioBuildingsActions as Actions,
  PaginatePortfolioBuildingsRequest as RequestActions,
} from "store/ducks/portfolioBuildings";

export function* paginatePortfolioBuildingsRequest(action: any) {
  const { query = {}, onSuccess, onFailure }: RequestActions = action;
  try {
    const url = applyQueryParams("portfolio-buildings", query);
    const { data } = yield call(api.get, url);
    onSuccess && onSuccess();
    yield put(Actions.success(data.data, data.meta));
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    onFailure && onFailure();
    notify("error", errorMessage);
    yield put(Actions.failure(errorMessage));
  }
}
