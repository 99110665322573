import React from "react";
import { Scaffold } from "components/Shared";
import { AboutUsSections } from "components/About";
import * as S from "./styles";

const shwowMainContent = false;

export const About: React.FC = () => {
  return (
    <Scaffold background>
      <AboutUsSections />
      {shwowMainContent && (
        <S.Container>
          <S.Background />
          <S.Content>
            <S.Title>
              Temos a missão de realizar os melhores empreendimentos{" "}
              <span>residenciais e comerciais</span>.
            </S.Title>
            <S.Text>
              <p>
                Nossos empreendimentos são realizados com engenharia de alto
                padrão e transparência, pensando em pessoas. Assim, mais do que
                entregar um imóvel, viabilizamos sonhos.
              </p>

              <p>
                Lideramos nossa empresa com exemplos de uma presidência
                experiente e comprometida com resultados, tendo a frente o
                Engenheiro Civil Gustavo Cesar, que trouxe à sua cidade natal
                mais de 10 anos de uma trajetória de sucesso da maior
                Incorporadora da América Latina:
              </p>
            </S.Text>
            <S.BoxesWrapper>
              <S.Box>
                <S.BoxSubtitle>2009</S.BoxSubtitle>
                <S.BoxTitle>100 milhões</S.BoxTitle>
                <S.BoxText>
                  Em 2009, conquistou o prêmio de gerente do ano pela Cyrela
                  Brazil Realty, com mais de R$ 100 milhões em valor de vendas;
                </S.BoxText>
              </S.Box>
              <S.Box>
                <S.BoxSubtitle>2010</S.BoxSubtitle>
                <S.BoxTitle>250 milhões</S.BoxTitle>
                <S.BoxText>
                  Em 2010, expandiu sua equipe, tornando-se diretor de vendas de
                  seu departamento. As vendas atingiram o expressivo valor de R$
                  250 milhões;
                </S.BoxText>
              </S.Box>
              <S.Box>
                <S.BoxSubtitle>2011</S.BoxSubtitle>
                <S.BoxTitle>565 milhões</S.BoxTitle>
                <S.BoxText>
                  O ano de 2011 tornou-se um marco na história das operações da
                  empresa no Rio de Janeiro. O faturamento de sua equipe chegou
                  aos R$ 565 milhões em vendas.
                </S.BoxText>
              </S.Box>
            </S.BoxesWrapper>
            <S.Text>
              <p>
                Temos a missão de realizar os melhores empreendimentos
                residenciais e comerciais, fundamentando nossas ações de acordo
                com padrões profissionais, apoiados na meticulosa escolha do
                local, na fina concepção dos projetos arquitetônicos, na
                engenharia eficiente e sustentável e na absoluta solidez
                financeira.
              </p>

              <p>
                Perpetuamos nossos negócios pela rentabilidade, qualidade na
                entrega, solidez e relacionamento com o cliente, colaboradores e
                investidores.
              </p>

              <p>
                Trabalhamos com padrões de excelência em tudo o que fazemos,
                entregando produtos e serviços de alta qualidade e dentro dos
                prazos acordados.
              </p>
            </S.Text>
            <S.Subtitle>
              Tudo isso para você aproveitar o melhor da vida.
            </S.Subtitle>
            <S.Text signature>
              Viver em um de nossos empreendimentos significa usufruir a vida em
              um espaço único, projetado em cada detalhe com materiais de
              primeira linha, inovadores, com qualidade construtiva, acabamento
              diferenciado e áreas comuns equipadas e decoradas.
            </S.Text>
            <S.Signature />
          </S.Content>
        </S.Container>
      )}
    </Scaffold>
  );
};
