import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { Scaffold, PortfolioBuildingBox } from "components/Shared";
import {
  ListPortfolioBuildingsActions as ListActions,
  ListPortfolioBuildingsState as ListState,
} from "store/ducks/portfolioBuildings";
import * as S from "./styles";

export const Portfolio: React.FC = () => {
  const dispatch = useDispatch();

  const { data: buildings } = useSelector<RootStateOrAny, ListState>(
    (state) => state.listPortfolioBuildings
  );

  const fetchList = useCallback(() => {
    dispatch(ListActions.request());
  }, [dispatch]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  useEffect(() => {
    return () => {
      dispatch(ListActions.reset());
    };
  }, [dispatch]);

  return (
    <Scaffold background={true}>
      <S.Container>
        <S.Background />
        <S.Content>
          <S.Title>
            Confira nosso portfólio
            <br />
            de empreendimentos
          </S.Title>
          {/* <S.Subtitle>Empreendimentos com vendas concluídas:</S.Subtitle> */}
          <S.Wrapper>
            {buildings.map((b) => (
              <PortfolioBuildingBox key={b.id} building={b} />
            ))}
          </S.Wrapper>
          <S.SoldTitle>Veja também nossos empreendimentos à venda</S.SoldTitle>
          <S.SoldText>
            Invista você também nos seus sonhos e adquira seu imóvel em um dos
            nossos empreendimentos!
          </S.SoldText>
          <S.LinkButton to="/" btStyle="secondary">
            Ver Empreendimentos à venda
          </S.LinkButton>
        </S.Content>
      </S.Container>
    </Scaffold>
  );
};
