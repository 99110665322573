import styled from "styled-components";
import { colors } from "styles";

export const Container = styled.section`
  width: 100%;
  margin: 0 auto;
  position: relative;
  background-color: white;
`;

export const Background = styled.div`
  width: 100%;
  height: 460px;
  background-color: ${colors.lightGray};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 80px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 36px;
  color: ${colors.dark};
  text-align: center;
  width: 100%;
  max-width: 440px;
  margin-bottom: 54px;

  @media screen and (max-width: 414px) {
    font-size: 30px;
  }
  @media screen and (max-width: 375px) {
    font-size: 24px;
  }
`;
