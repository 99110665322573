import React, { useCallback } from "react";
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Building } from "contracts/buildings";
import * as M from "./mapDefinitions";
import * as S from "./styles";

type Props = {
  building: Building;
};

const MBox = ReactMapboxGl({
  accessToken: M.accessToken,
});

export const Map: React.FC<Props> = ({ building }) => {
  const AddressComponent: React.FC = useCallback(() => {
    const {
      address_street,
      address_number,
      address_neighborhood,
      address_city,
    } = building;
    return (
      <S.AddressContainer>
        <S.BuilingIcon />
        <S.MainContent>
          <S.Title>ENDEREÇO DO EMPREENDIMENTO:</S.Title>
          <S.Text>
            {address_street}, {address_number}, {address_neighborhood},{" "}
            {address_city}
          </S.Text>
        </S.MainContent>
      </S.AddressContainer>
    );
  }, [building]);

  const MapComponent: React.FC = useCallback(() => {
    const { address_latitude, address_longitude } = building;
    if (address_latitude === null || address_longitude === null) {
      return <></>;
    }
    return (
      <S.MainContainer>
        <MBox
          style={M.styleUrl}
          zoom={[16]}
          center={[address_longitude, address_latitude]}
          containerStyle={{
            width: "inherit",
            height: "inherit",
          }}
        >
          <Marker coordinates={[address_longitude, address_latitude]}>
            <S.MarkerIcon />
          </Marker>
        </MBox>
        <AddressComponent />
      </S.MainContainer>
    );
  }, [AddressComponent, building]);

  return <MapComponent />;
};
