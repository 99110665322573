import styled, { css } from "styled-components";
import { colorScheme, colors } from "styles";

import { MenuAltRight } from "@styled-icons/boxicons-regular";
import { Close } from "@styled-icons/evaicons-solid";

import logo from "assets/images/logo-white.png";

export const Container = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Web = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 920px) {
    display: none;
  }
`;

type OptionContract = {
  transform?: "yes" | "no";
};

export const Option = styled.button.attrs({ type: "button" })<OptionContract>`
  text-transform: uppercase;
  font-size: 13px;
  margin-right: 24px;
  transition: 300ms ease;
  color: white;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: ${colorScheme.primary};
  }

  ${({ transform }) =>
    transform &&
    css`
      color: ${colors.dark};
    `}
`;

export const MenuButton = styled.button.attrs({ type: "button" })`
  display: none;

  @media screen and (max-width: 920px) {
    display: flex;
  }
`;

export const MenuIcon = styled(MenuAltRight).attrs({ size: 24 })`
  color: ${colors.dark};
`;

export const Overlay = styled.div<{ open: Boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background-color: ${colors.dark};
  z-index: 1000;
  top: 0;
  left: 0;
  overflow: auto;
`;

export const CloseIcon = styled(Close).attrs({ size: 32 })`
  color: white;
  position: absolute;
  top: 32px;
  right: 32px;
`;

export const CloseButton = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img.attrs({ src: logo })`
  width: 100%;
  max-width: max-content;
  max-height: max-content;
  margin-bottom: 24px;
`;

export const OptionsWrapper = styled.div`
  border-top: 1px #ffffff80 solid;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;

  button {
    padding: 16px 0;
    margin: 0;
  }
`;
