import styled from "styled-components";

import { ChevronLeft, ChevronRight } from "@styled-icons/boxicons-regular";
import { colors } from "styles";

export const MainContainer = styled.section.attrs({
  className: "plans-gallery",
})`
  background-color: #fff;
  padding: 54px 0;
  @media screen and (max-width: 890px) {
    padding-left: 32px;
    padding-right: 32px;
  }
`;

// header

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 48px;
`;

export const Title = styled.h3`
  width: 100%;
  max-width: 600px;
  font-size: 36px;
  color: ${colors.dark};
  text-align: center;
  margin-bottom: 24px;

  @media screen and (max-width: 500px) {
    font-size: 30px;
    padding: 48px 0 0 0;
  }
  @media screen and (max-width: 375px) {
    font-size: 24px;
  }
`;

export const Subtitle = styled.article`
  font-size: 15px;
  text-align: center;
`;

// slider

export const Gallery = styled.div`
  width: 100%;
  max-width: 1270px;
  position: relative;
  margin: 0 auto;

  .slick-prev:before,
  .slick-next:before {
    content: "";
    display: none;
  }
`;

interface ISlide {
  imageSrc: string;
}

export const Slide = styled.div<ISlide>`
  width: 100%;
  height: 70vh;
  background-image: url(${({ imageSrc }) => imageSrc});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @media all and (max-width: 500px) {
    height: 30vh;
  }
`;

export const PrevArrow = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: #ededed;
  position: absolute;
  left: 0px;
  z-index: 99;

  &:hover,
  &:focus {
    color: initial !important;
    background: #ededed !important;
  }

  @media screen and (max-width: 500px) {
    display: none !important;
  }
`;

export const NextArrow = styled(PrevArrow)`
  left: auto;
  right: 0px;
  z-index: 99;
`;

export const LeftIcon = styled(ChevronLeft).attrs({ size: 24 })`
  color: ${colors.dark};
`;

export const RightIcon = styled(ChevronRight).attrs({ size: 24 })`
  color: ${colors.dark};
`;

export const Disclaimer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  p {
    font-size: 12px;
    text-align: center;
    max-width: 570px;
  }
`;
