import { createGlobalStyle } from "styled-components";
import NexaBold from "assets/fonts/Nexa-Bold.otf";
import NexaLight from "assets/fonts/Nexa-Light.otf";

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "NexaBold";
    src: url(${NexaBold});
  }

  @font-face {
    font-family: "NexaLight";
    src: url(${NexaLight});
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  html,
  body,
  #root {
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background-color: white;
    font-family: "muli", sans-serif;
    font-size: 16px;
    color: #4A525B;
  }

  body.modal-open {
    overflow-y: hidden;
  }

  body.react-select-open {
    .modal-body {
      overflow-y: auto;
      .react-select__menu {
        position: sticky;
      }
    }
  }

  a {
    text-decoration: none;
  }

  button {
    font-family: "Montserrat";
    font-weight: 700;
    border: 0;
    cursor: pointer;
    background-color: transparent;
  }

  input, textarea {
    font-family: "muli", sans-serif;
    font-weight: 400;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Montserrat";
    font-weight: 700;
  }

  ul {
    list-style: none;
  }

  .toastsuccess {
    border-radius: 6px;
    background-color: #1460A8 !important;
  }

  .Toastify__toast--error {
    border-radius: 6px;
    background-color: #ec1751 !important;
  }

  .Toastify__toast-body {
    font-family: "Montserrat" !important;
    font-weight: 700;
    font-size: 14px;
    padding: 0 16px;
  }

  .icon-spin {
    animation: iconSpin 2s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  // extra bottom padding if next sibling is the FeaturesAndProximity component
  .next-sibling-is-features-and-proximity {
    padding-bottom: 210px;
  }
`;

export default GlobalStyle;
