import { call, put } from "redux-saga/effects";
import { api, notify } from "services";
import { requestErrorHandler } from "utils";
import {
  FetchBuildingActions as Actions,
  FetchBuildingRequest as RequestActions,
} from "store/ducks/buildings";

export function* fetchBuildingRequest(action: any) {
  const { id }: RequestActions = action;
  try {
    const { data } = yield call(api.get, `buildings/${id}`);

    yield put(Actions.success(data));
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(Actions.failure(errorMessage));
  }
}
