import styled from "styled-components";
import { Download } from "@styled-icons/material/Download";
import BookImg from "assets/images/book.png";
import { colors, fonts } from "styles";

export const MainContainer = styled.section`
  background-color: ${colors.dark};
  padding: 32px 0px;
  @media screen and (max-width: 540px) {
    padding: 32px 32px;
  }
`;

export const ContentConstrainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 460px;
  color: #fff;
`;

// header

export const Title = styled.h3`
  font-family: ${fonts.NexaBold};
  font-size: 34px;
  text-align: center;
  margin-bottom: 48px;
  span {
    color: ${colors.orange};
  }
`;

// main content

export const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  border: 2px solid #707070;
  border-radius: 6px;
  padding: 24px 32px;
  @media screen and (max-width: 540px) {
    flex-direction: column;
  }
`;

export const IconArea = styled.div`
  @media screen and (max-width: 540px) {
    text-align: center;
    margin-bottom: 24px;
  }
`;

export const BookIcon = styled.img.attrs({
  src: `${BookImg}`,
})`
  max-width: 48px;
`;

// download area
export const DownloadArea = styled.div`
  margin-left: 24px;
  p {
    font-size: 15px;
    margin-bottom: 8px;
  }
  @media screen and (max-width: 540px) {
    margin-left: 0px;
    text-align: center;
    p {
      margin-bottom: 24px;
    }
  }
`;

export const DownloadButton = styled.button.attrs({ type: "button" })`
  color: inherit;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  span > span {
    color: ${colors.orange};
  }
  @media screen and (max-width: 540px) {
    margin: 0 auto;
  }
`;

export const DownloadIcon = styled(Download).attrs({
  size: 18,
  color: colors.orange,
})`
  margin-right: 8px;
`;
