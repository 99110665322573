import React, { useCallback } from "react";
import DefaultImage from "assets/images/default-featured-image.jpg";
import { PaginatedPortfolioBuilding } from "contracts/portfolioBuildings";
import { Formatter } from "utils";
import * as S from "./styles";

type Props = {
  building: PaginatedPortfolioBuilding;
};

export const PortfolioBuildingBox: React.FC<Props> = ({ building }) => {
  const DetailsHeaderComponent = useCallback((): JSX.Element => {
    if (!building?.location) return <></>;

    return (
      <S.DetailsHeader>
        <S.HeaderMainContent>
          <p>{building.location}</p>
        </S.HeaderMainContent>
      </S.DetailsHeader>
    );
  }, [building]);

  const BuildingDetailsComponent = useCallback((): JSX.Element => {
    const { type, name, reference_date } = building;

    return (
      <S.DetailsContainer>
        <DetailsHeaderComponent />
        <S.BuildingName>{name}</S.BuildingName>

        <S.DetailRow>
          <S.DetailLabel>Atuação:</S.DetailLabel> {type}
        </S.DetailRow>

        {reference_date && (
          <S.DetailRow>
            <S.DetailLabel>Data:</S.DetailLabel>{" "}
            {Formatter.date(reference_date)}
          </S.DetailRow>
        )}
      </S.DetailsContainer>
    );
  }, [DetailsHeaderComponent, building]);

  return (
    <S.MainContainer>
      <S.InnerContainer>
        <S.Overlay />
        <S.ImageContainer>
          <img src={building?.imagepath || DefaultImage} alt={building.name} />
        </S.ImageContainer>
      </S.InnerContainer>
      <BuildingDetailsComponent />
    </S.MainContainer>
  );
};
