import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import { Building } from "contracts/buildings";

export interface FetchBuildingState {
  data: Building | null;
  loading: boolean;
  error: string | null;
}

export interface FetchBuildingRequest {
  id: string;
}

interface SuccessAction {
  data: Building;
}

interface FailureAction {
  error: string;
}

const { Types, Creators } = createActions(
  {
    request: ["id"],
    success: ["data"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "FETCH_BUILDING_" }
);

const INITIAL_STATE: FetchBuildingState = {
  data: null,
  loading: false,
  error: null,
};

const request = (state: FetchBuildingState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: FetchBuildingState, action: SuccessAction) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });

const failure = (state: FetchBuildingState, action: FailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const fetchBuilding = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const FetchBuildingTypes = Types;
export const FetchBuildingActions = Creators;
