import React, { useState, useCallback, useEffect } from "react";

import * as S from "./styles";
import { Navigation } from "./Navigation";

interface IHeaderProps {
  background?: boolean;
}

export const Header: React.FC<IHeaderProps> = ({ background = false }) => {
  const [transform, setTransform] = useState<boolean>(background);

  const handleScroll = useCallback(() => {
    if (window.scrollY < 60 && !background) {
      setTransform(false);
    } else {
      setTransform(true);
    }
  }, [background]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <S.Container transform={transform ? "yes" : "no"}>
      <a href="/">
        <S.Logo transform={transform ? "yes" : "no"} />
      </a>
      <Navigation transform={transform} />
    </S.Container>
  );
};
