import React from "react";

import * as S from "./styles";

export const WannaKnowMore: React.FC = () => {
  return (
    <S.MainContainer>
      <S.ContentConstrainer>
        {/* <S.Content>
          <S.Header>
            <S.Tittle>Quer saber mais ?</S.Tittle>
            <S.Text>
              Fale com nosso corretor e saiba mais sobre preços e condições de
              pagamento do seu novo imóvel!
            </S.Text>
          </S.Header>
          <S.Actions>
            <S.ActionButton
              onClick={() =>
                window.open("https://api.whatsapp.com/send?phone=552433481020")
              }
              btStyle="primary"
            >
              <S.WhatsIcon />
              (24) 3348-1020
            </S.ActionButton>
            <S.ActionButton
              onClick={() =>
                window.open(
                  "https://www.redeplan.com/view//html/inc/chat.html",
                  "_blank"
                )
              }
              btStyle="secondary"
            >
              <S.ChatIcon />
              Chat Online
            </S.ActionButton>
          </S.Actions>
        </S.Content> */}
      </S.ContentConstrainer>
    </S.MainContainer>
  );
};
