import React, { useEffect, useCallback } from "react";
import Slider, { Settings } from "react-slick";
import { useParams } from "react-router-dom";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import Truncatise from "truncatise";
import { Scaffold, BuildingsForSale } from "components/Shared";
import { BuildingBanner } from "components/Building";
import {
  PlansGallery,
  FeaturesAndProximity,
  Book,
  WannaKnowMore,
  Map,
} from "components/Building";
import {
  FetchBuildingActions as FetchActions,
  FetchBuildingState as FetchState,
} from "store/ducks/buildings";
import * as S from "./styles";

const sliderSettings: Settings = {
  slidesToShow: 1,
  speed: 500,
  nextArrow: (
    <S.NextArrow>
      <S.IconChevronRight />
    </S.NextArrow>
  ),
  prevArrow: (
    <S.PrevArrow>
      <S.IconChevronLeft />
    </S.PrevArrow>
  ),
  responsive: [
    {
      breakpoint: 501,
      settings: {
        dots: true,
      },
    },
  ],
};

type RouteParams = {
  buildingId: string;
};

export const Building: React.FC = () => {
  const dispatch = useDispatch();
  const { buildingId } = useParams<RouteParams>();

  const { data: building, loading: fetchingBuilding } = useSelector<
    RootStateOrAny,
    FetchState
  >((state) => state.fetchBuilding);

  const HeaderBannerComponent: React.FC = useCallback(() => {
    if (fetchingBuilding) return <S.ActivityIndicator />;
    if (!building) return <></>;
    return <BuildingBanner building={building} />;
  }, [building, fetchingBuilding]);

  const ImageGalleryComponent: React.FC = useCallback(() => {
    return (
      <S.Gallery>
        {fetchingBuilding && <S.ActivityIndicator />}
        <Slider {...sliderSettings}>
          {building?.galleryImages.map((image) => (
            <S.Slide
              key={image.id}
              imageSrc={image.filepath}
              title={image.description || ""}
            />
          ))}
        </Slider>
      </S.Gallery>
    );
  }, [building, fetchingBuilding]);

  const LogoComponent: React.FC = useCallback(() => {
    if (fetchingBuilding) return <S.ActivityIndicator />;
    if (!building?.logoImageFilepath) return <></>;
    return <S.Logo src={building.logoImageFilepath} alt={building?.name} />;
  }, [building, fetchingBuilding]);

  const MapComponent: React.FC = useCallback(() => {
    if (fetchingBuilding) return <S.ActivityIndicator />;
    if (!building) return <></>;
    return <Map building={building} />;
  }, [building, fetchingBuilding]);

  const fetchBuilding = useCallback((): void => {
    dispatch(FetchActions.request(buildingId));
  }, [dispatch, buildingId]);

  useEffect(() => {
    fetchBuilding();
  }, [fetchBuilding]);

  useEffect(() => {
    return () => {
      dispatch(FetchActions.reset());
    };
  }, [dispatch]);

  return (
    <Scaffold background>
      <HeaderBannerComponent />
      <S.Container>
        <S.Content>
          <S.Title>
            Conheça este
            <br />
            empreendimento
          </S.Title>
          <S.Wrapper>
            <ImageGalleryComponent />
            <S.About>
              <LogoComponent />
              <S.TextWrapper>
                <S.AboutTitle>{building?.long_description_title}</S.AboutTitle>
                <S.AboutText>
                  {Truncatise(building?.long_description || "", {
                    TruncateBy: "characters",
                    TruncateLength: 200,
                  })}
                </S.AboutText>
              </S.TextWrapper>
            </S.About>
          </S.Wrapper>
        </S.Content>
        {building?.plans?.length && <PlansGallery plans={building.plans} />}
        {(building?.features || building?.proximity) && (
          <FeaturesAndProximity
            features={building?.features}
            proximity={building?.proximity}
          />
        )}
        {building?.bookFilepath && (
          <Book bookFilepath={building.bookFilepath} />
        )}
        <WannaKnowMore />
        <MapComponent />
        <BuildingsForSale />
      </S.Container>
    </Scaffold>
  );
};
