import styled from "styled-components";
import { Whatsapp } from "@styled-icons/boxicons-logos";
import logo from "assets/images/logo-footer.png";
import { colors } from "styles";
import { Button } from "styles/styled-components";

export const Container = styled.footer`
  width: 100%;
  background-color: ${colors.dark};
  padding: 72px 32px;
`;

export const Content = styled.section`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 940px) {
    flex-direction: column;
    max-width: max-content;
    align-items: center;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 72px;
  flex: 1;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 1024px) {
    margin-right: 40px;
  }

  @media screen and (max-width: 940px) {
    margin-right: 0;
    margin-bottom: 40px;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Logo = styled.img.attrs({ src: logo })`
  width: 100%;
  max-width: max-content;
  max-height: max-content;
  margin-bottom: 32px;
`;

export const Text = styled.article`
  font-size: 16px;
  line-height: 20px;
  color: white;
  width: 100%;
  max-width: 420px;

  a {
    font-size: 20px;
    font-weight: 700;
    display: block;
    cursor: pointer;
    color: ${colors.white};
  }

  p {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      font-weight: 700;
      text-decoration: underline;
      color: white;
    }
  }

  @media screen and (max-width: 940px) {
    text-align: center;
  }
`;

export const Title = styled.h4`
  font-size: 20px;
  line-height: 20px;
  color: white;
  text-transform: uppercase;
  margin-bottom: 32px;
`;

export const WhatsappWrapper = styled.div`
  display: flex;
  margin-bottom: 32px;
  color: white;
`;

export const WhatsappIcon = styled(Whatsapp).attrs({ size: 40 })`
  color: ${colors.orange};
  margin-right: 8px;
`;

export const ButtonChat = styled(Button)`
  width: max-content;

  @media screen and (max-width: 940px) {
    align-self: center;
  }
`;
