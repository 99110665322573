import React from "react";

import * as S from "./styles";

export const Footer: React.FC = () => {
  return (
    <S.Container>
      <S.Content>
        <S.Column>
          <S.Logo />
          <S.Text>
            Temos a missão de realizar os melhores empreendimentos residenciais
            e comerciais, fundamentando nossas ações de acordo com os melhores
            padrões profissionais.
          </S.Text>
        </S.Column>
        <S.Column>
          <S.Title>Contato</S.Title>
          <S.Text>
            <p>
              Rua 27, nº 56 - Vila Santa Cecília Cep: 27260-250 - Volta Redonda
              - RJ
            </p>
            <p>TEL: 55 24 3342-9118</p>
            <p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/maps/@-22.521161,-44.1057928,3a,75y,251.29h,84.64t/data=!3m6!1e1!3m4!1soxcBUUNmGfQGNXBts3FbIw!2e0!7i13312!8i6656"
              >
                Ver Mapa
              </a>
            </p>
          </S.Text>
        </S.Column>
        {/* <S.Column>
          <S.Title>Vendas (RedePlan)</S.Title>
          <S.WhatsappWrapper>
            <S.WhatsappIcon />
            <S.Column>
              <S.Text>
                Telefone e Whatsapp:
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://api.whatsapp.com/send?phone=552433481020"
                >
                  24 3348-1020
                </a>
              </S.Text>
            </S.Column>
          </S.WhatsappWrapper>
          <S.ButtonChat
            onClick={() =>
              window.open(
                "https://www.redeplan.com/view//html/inc/chat.html",
                "_blank"
              )
            }
            btStyle="outlineLight"
          >
            Chat online
          </S.ButtonChat>
        </S.Column> */}
      </S.Content>
    </S.Container>
  );
};
