import React, { ReactNode, useCallback } from "react";

import { useDimensions } from "hooks";
import * as S from "./styles";

interface Step {
  name: string;
  description: string;
  icon: ReactNode;
  getOrder: () => number;
}

export const StepsInfo: React.FC = () => {
  const { width } = useDimensions();

  const getSteps = useCallback((): Step[] => {
    return [
      {
        name: "1. Projetos Complementares",
        description:
          "Desenvolvimento de todos os projetos necessários para a realização da obra, com exceção do Projeto Legal, que se encontra pronto. Os projetos mais comuns são: Estrutural, Fundações, Instalações e Segurança contra Incêndio e Pânico.",
        icon: <S.PlanningIcon />,
        getOrder: () => {
          switch (true) {
            default:
              return 1;
          }
        },
      },
      {
        name: "2. Demolição/Movimento de Terra",
        description:
          "É a retirada e movimentação de solo do terreno para se atingir a profundidade ou cota necessária para a execução da construção.",
        icon: <S.LandworkIcon />,
        getOrder: () => {
          switch (true) {
            case width >= 1001:
              return 4;
            default:
              return 2;
          }
        },
      },
      {
        name: "3. Fundações",
        description:
          "Geralmente enterrada, é a parte da obra que serve para suportar a construção, distribuindo seu peso no terreno. O tipo utilizado depende do terreno, sendo os mais comuns: baldrame, sapata, estaca e tubulão.",
        icon: <S.FoundationsIcon />,
        getOrder: () => {
          switch (true) {
            case width >= 1001:
              return 6;
            default:
              return 3;
          }
        },
      },
      {
        name: "4. Estrutura",
        description:
          "É formada por vários elementos, como pilares, vigas e lajes, e tem a função de suportar as cargas de acordo com a formatação do edifício e suas divisões internas. Pode ser de diversas formas e materiais, como madeira, concreto armado ou metal.",
        icon: <S.StructureIcon />,
        getOrder: () => {
          switch (true) {
            case width >= 1001:
              return 2;
            default:
              return 4;
          }
        },
      },
      {
        name: "5. Alvenaria",
        description:
          "São as paredes da construção, normalmente feitas de tijolos ou blocos sobrepostos e unidos por argamassa, que podem servir tanto como vedação ou como estrutura, sendo essa segunda chamada de alvenaria estrutural.",
        icon: <S.BrickworkIIcon />,
        getOrder: () => {
          switch (true) {
            case width >= 1001:
              return 5;
            default:
              return 5;
          }
        },
      },
      {
        name: "6. Instalações",
        description:
          "Trata-se da execução das instalações hidráulicas em geral, de gás, elétricas e especiais, como telefonia, dados e de proteção contra descargas elétricas (pára-raios).",
        icon: <S.InstallationsIcon />,
        getOrder: () => {
          switch (true) {
            case width >= 1001:
              return 7;
            default:
              return 6;
          }
        },
      },
      {
        name: "7. Fachada",
        description:
          "São as finalizações e arremates do térreo e da fachada do edifício, que inclui diversos tipos de revestimentos, além do paisagismo e áreas externas de lazer, como piscinas, playground, churrasqueiras e outros.",
        icon: <S.FacadeIcon />,
        getOrder: () => {
          switch (true) {
            case width >= 1001:
              return 3;
            default:
              return 7;
          }
        },
      },
      {
        name: "8. Acabamento",
        description:
          "É a finalização e arremate de todos os elementos internos da edificação, a fim de deixá-la pronta para ocupação. Consiste na aplicação dos revestimentos de paredes, pisos e tetos e na colocação dos equipamentos como louças, metais sanitários, tomadas e interruptores.",
        icon: <S.FinishingIcon />,
        getOrder: () => {
          switch (true) {
            default:
              return 8;
          }
        },
      },
    ].sort((prev, curr) => {
      if (prev.getOrder() < curr.getOrder()) return -1;
      if (prev.getOrder() > curr.getOrder()) return 1;
      return 0;
    });
  }, [width]);

  return (
    <S.MainContainer>
      <S.Header>
        <S.HeaderTitle>Entenda as etapas da obra</S.HeaderTitle>
        <S.HeaderSubtitle>
          Saiba mais sobre como acontece todo o processo de edificação do
          empreendimento.
        </S.HeaderSubtitle>
      </S.Header>
      <S.MainContent>
        {getSteps().map((step) => (
          <S.Step key={step.name}>
            <S.StepIcon>{step.icon}</S.StepIcon>
            <S.StepTitle>{step.name}</S.StepTitle>
            <S.StepDescription>{step.description}</S.StepDescription>
          </S.Step>
        ))}
      </S.MainContent>
    </S.MainContainer>
  );
};
