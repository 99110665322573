import styled from "styled-components";
import { MapMarkerAlt } from "@styled-icons/fa-solid/MapMarkerAlt";
import BuildingImg from "assets/images/icon-building.png";
import { colors, fonts } from "styles";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 590px;
`;

export const MarkerIcon = styled(MapMarkerAlt).attrs({
  size: 64,
  color: colors.blue,
})``;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 40px;
  background-color: #fff;
  width: 100%;
  max-width: 840px;
  border-left: 6px solid ${colors.orange};
  padding: 32px;

  @media screen and (max-width: 840px) {
    position: relative;
    bottom: auto;
    border-left: none;
    border-top: 6px solid ${colors.orange};
  }
`;

export const BuilingIcon = styled.img.attrs({ src: `${BuildingImg}` })`
  margin-right: 32px;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h4`
  font-family: ${fonts.Muli};
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`;

export const Text = styled.p`
  text-transform: uppercase;
  font-size: 15px;
`;
