export const applyQueryParams = (
  url: string,
  params: Record<string, any>
): string => {
  const validObject: Record<string, any> = {};

  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value) validObject[key] = value;
  });

  if (Object.keys(validObject).length === 0) return url;

  const query = new URLSearchParams(validObject).toString();
  return `${url}?${query}`;
};
