import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Building } from "contracts/buildings";
import * as S from "./styles";

type Props = {
  building: Building;
};

export const BuildingBanner: React.FC<Props> = ({ building }) => {
  const history = useHistory();

  const MainContentComponent = useCallback((): JSX.Element => {
    const {
      name,
      address_street,
      address_number,
      address_neighborhood,
      address_city,
      address_state,
      buildingType,
      sales_percentage,
    } = building;

    const subtitle =
      buildingType.name === "Apartamentos" ? "Residencial" : "Comercial";

    return (
      <S.MainContent>
        <S.Wrapper>
          <S.Subtitle>{subtitle}</S.Subtitle>
          <S.Title>{name}</S.Title>
          <S.City>
            {address_neighborhood}, {address_city}
          </S.City>
          <S.Address>
            {address_street}, {address_number}, {address_neighborhood},{" "}
            {address_city}-{address_state}
          </S.Address>
          <S.StatusWrapper>
            <S.IconLike /> {sales_percentage}% vendido
          </S.StatusWrapper>
        </S.Wrapper>
      </S.MainContent>
    );
  }, [building]);

  const FooterComponent = useCallback((): JSX.Element => {
    const { size, lofts, bedrooms, parking_lots, sales_percentage } = building;

    const salesState =
      sales_percentage === 100 ? "Vendas concluídas" : "Vendas disponíveis";

    return (
      <S.BarBottom>
        <S.BoxStatus>
          <S.IconLikeBar />
          {salesState}
        </S.BoxStatus>
        <S.BoxContent>
          {size && (
            <S.BoxItem>
              <S.Iconhouse /> {size}
            </S.BoxItem>
          )}
          {lofts && (
            <S.BoxItem>
              <S.IconLoft /> {lofts}
            </S.BoxItem>
          )}
          {parking_lots && (
            <S.BoxItem>
              <S.IconGarage /> {parking_lots}
            </S.BoxItem>
          )}
          {bedrooms && (
            <S.BoxItem>
              <S.IconBed /> {bedrooms}
            </S.BoxItem>
          )}
        </S.BoxContent>
        <S.ButtonSale onClick={() => history.push("/")}>
          <S.IconHome />
          <div>
            <p>
              Veja empreendimentos à venda <span>clicando aqui</span>
            </p>
          </div>
        </S.ButtonSale>
      </S.BarBottom>
    );
  }, [building, history]);

  return (
    <S.Container>
      <S.ImageContent bgImage={building.headerImageFilepath} />
      <MainContentComponent />
      <FooterComponent />
    </S.Container>
  );
};
