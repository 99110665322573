export const colors = {
  white: "#fff",
  lightGray: "#EDEDED",
  gray: "#eaeaea",
  black: "#2B2B2B",
  dark: "#24282E",
  green: "#3BBCA3",
  blueGray: "#4A525B",
  blue: "#1460A8",
  red: "#ec1751",
  darkOrange: "#EA4335",
  orange: "#F98727",
};

export const colorScheme = {
  primary: colors.blue,
  secondary: colors.orange,
  text: colors.blueGray,
};
