import React, { useCallback } from "react";

import * as S from "./styles";

type Props = {
  bookFilepath: string;
};

export const Book: React.FC<Props> = ({ bookFilepath }) => {
  const handleDownload = useCallback((): void => {
    const extension = bookFilepath.match(/\.[0-9a-z]+$/i)?.[0];
    if (!extension) return;

    const filename = `book${extension}`;

    const anchor: HTMLAnchorElement = document.createElement("a");

    anchor.href = bookFilepath;
    anchor.download = filename; // download param only works on same origin
    anchor.target = "_blank";

    anchor.click();
  }, [bookFilepath]);

  return (
    <S.MainContainer>
      {/* <S.ContentConstrainer>
        <S.Title>
          Faça <span>download</span> do book:
        </S.Title>
        <S.MainContent>
          <S.IconArea>
            <S.BookIcon />
          </S.IconArea>
          <S.DownloadArea>
            <p>
              Confira todos os detalhes do empreedimento em nosso book de
              apresentação!
            </p>
            <S.DownloadButton onClick={handleDownload}>
              <S.DownloadIcon />
              <span>
                Baixe <span> aqui</span>
              </span>
            </S.DownloadButton>
          </S.DownloadArea>
        </S.MainContent>
      </S.ContentConstrainer> */}
    </S.MainContainer>
  );
};
