import styled from "styled-components";
import { ChevronLeft, ChevronRight } from "@styled-icons/boxicons-regular";
import { fonts, colors } from "styles";

export const MainContainer = styled.section``;

// header

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 72px auto;
  padding: 64px 0px 0px 0px;
  max-width: 420px;

  form {
    width: 100%;
    margin-top: 32px;
  }
`;

export const HeaderTitle = styled.h4`
  text-align: center;
  font-family: ${fonts.NexaBold};
  font-size: 36px;
  margin-bottom: 24px;
`;

export const HeaderSubtitle = styled.p`
  font-size: 15px;
  text-align: center;
`;

// gallery

export const Gallery = styled.div`
  width: 100%;
  max-width: 1270px;
  position: relative;
  margin: 0 auto;

  .slick-prev:before,
  .slick-next:before {
    content: "";
    display: none;
  }

  @media screen and (max-width: 960px) {
    padding-bottom: 48px;
    padding: 0px 24px;
  }
`;

export const Slide = styled.div`
  padding: 0px 12px;
`;

export const SlideImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 24px;
  cursor: pointer;
`;

export const SlideDate = styled.div`
  text-transform: uppercase;
  border-top: 6px solid ${colors.blue};
  padding: 18px 0;
  font-size: 18px;
  font-weight: bold;
`;

export const ImageDescription = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  margin-top: -10px;
  margin-bottom: 20px;
`;

export const PrevArrow = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: #ededed;
  position: absolute;
  left: 0px;
  z-index: 99;
  transform: translateY(-62px);

  &:hover,
  &:focus {
    color: initial !important;
    background: #ededed !important;
  }

  @media screen and (max-width: 500px) {
    display: none !important;
  }
`;

export const NextArrow = styled(PrevArrow)`
  left: auto;
  right: 0px;
  z-index: 99;
`;

export const LeftIcon = styled(ChevronLeft).attrs({ size: 24 })`
  color: ${colors.dark};
`;

export const RightIcon = styled(ChevronRight).attrs({ size: 24 })`
  color: ${colors.dark};
`;

export const ModalContent = styled.div`
  max-width: 90vw;
  width: fit-content;
  position: relative;
`;

export const ImageContaienr = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 70vh;
  }
`;

export const CloseButton = styled.button.attrs({ type: "button" })`
  width: 32px;
  height: 32px;
  position: absolute;
  font-size: 24px;
  font-weight: bold;
  top: 16px;
  right: 16px;
  width: 30px;
  height: 30px;
  color: #ccc;
  border-radius: 50%;
  background-color: #fff;
`;
