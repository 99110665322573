import { combineReducers } from "redux";

import { listAboutUsBanners } from "./aboutUsBanners";
import {
  fetchBuilding,
  paginateBuildings,
  paginateBuildingsForSale,
  paginateBuildingsSold,
} from "./buildings";
import {
  listPortfolioBuildings,
  paginatePortfolioBuildings,
} from "./portfolioBuildings";

const reducers = combineReducers({
  // about us banners
  listAboutUsBanners,
  // buildings
  fetchBuilding,
  paginateBuildings,
  paginateBuildingsForSale,
  paginateBuildingsSold,
  // portfolio buildings
  listPortfolioBuildings,
  paginatePortfolioBuildings,
});

export default reducers;
