import axios from "axios";

const fixedIp = "172.17.6.55";

const environment = {
  local: {
    // api: "http://localhost:3333",
    api: "https://gustavo-cesar-api.herokuapp.com",
  },
  fixedIp: {
    api: `http://${fixedIp}:3333`,
  },
  production: {
    api: "https://gustavo-cesar-api.herokuapp.com",
  },
};

const baseURL =
  process.env.NODE_ENV === "development"
    ? environment.local
    : environment.production;

export const api = axios.create({
  baseURL: baseURL.api,
});
