import styled from "styled-components";
import { colors } from "styles";
export { Button } from "styles/styled-components";

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin: 0 auto;
  padding: 72px 32px;
`;

export const SoldTitle = styled.h3`
  width: 100%;
  max-width: 600px;
  font-size: 36px;
  color: ${colors.dark};
  text-align: center;
  margin-bottom: 24px;

  @media screen and (max-width: 500px) {
    font-size: 30px;
    padding: 48px 0 0 0;
  }
  @media screen and (max-width: 375px) {
    font-size: 24px;
  }
`;

export const SoldText = styled.article`
  width: 100%;
  max-width: 800px;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 48px;
  text-align: center;
  max-width: 530px;

  p {
    margin-bottom: 16px;

    :last-child {
      margin-bottom: 0;
    }
  }
`;
