import { call, put } from "redux-saga/effects";
import { api, notify } from "services";
import { requestErrorHandler, applyQueryParams } from "utils";
import {
  PaginateBuildingsSoldActions as Actions,
  PaginateBuildingsSoldRequest as RequestActions,
} from "store/ducks/buildings";

export function* paginateBuildingsSoldRequest(action: any) {
  const { query = {}, onSuccess, onFailure }: RequestActions = action;
  try {
    const url = applyQueryParams("buildings", { ...query, sold: true });
    const { data: responseData } = yield call(api.get, url);
    const { data, pagination } = responseData;
    onSuccess && onSuccess();
    yield put(Actions.success(data, pagination));
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    onFailure && onFailure();
    notify("error", errorMessage);
    yield put(Actions.failure(errorMessage));
  }
}
