import styled from "styled-components";
import defaultImageContentBg from "assets/images/new-building-teste.png";
import housePlan from "assets/images/icon-house-plan.png";
import { colors } from "styles";
import { Button } from "styles/styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1280px;
  margin-bottom: 80px;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

export const Content = styled.div`
  height: 510px;
`;

// main content

export const MainContent = styled(Content)`
  width: 448px;
  background-color: ${colors.blue};
  padding: 64px 56px;

  @media screen and (max-width: 980px) {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 414px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 24px;
  }
`;

export const Title = styled.h3`
  font-size: 28px;
  line-height: 28px;
  margin-bottom: 16px;
  color: white;

  @media screen and (max-width: 414px) {
    text-align: center;
  }
`;

export const City = styled.span`
  display: block;
  margin-bottom: 16px;
  font-size: 15px;
  font-weight: 700;
  color: ${colors.orange};
  text-transform: uppercase;

  @media screen and (max-width: 414px) {
    text-align: center;
  }
`;

export const IconPlan = styled.img.attrs({ src: housePlan })`
  width: 100%;
  max-width: max-content;
  height: 100%;
  max-height: max-content;
  margin-right: 24px;
`;

export const Text = styled.article`
  font-size: 16px;
  line-height: 20px;
  color: white;

  span {
    font-weight: 700;
  }

  @media screen and (max-width: 414px) {
    text-align: center;
  }
`;

export const PlanWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 40px 0;
  padding-top: 40px;
  border-top: 1px #ffffff60 solid;

  @media screen and (max-width: 414px) {
    justify-content: center;
  }
`;

export const KnowMoreButton = styled(Button)``;

// image content

interface IImageContent {
  bgImage: string | null;
}

export const ImageContent = styled(Content)<IImageContent>`
  flex: 1;
  background-color: #ccc;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: ${({ bgImage }) =>
    `url('${bgImage || defaultImageContentBg}')`};
  margin-right: 8px;

  @media screen and (max-width: 980px) {
    width: 100%;
    flex: none;
  }

  @media screen and (max-width: 414px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 414px;
  }
`;
