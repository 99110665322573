import styled, { css } from "styled-components";
import ass from "assets/images/ass-gustavo.png";

import { colors, colorScheme, fonts } from "styles";

export const Container = styled.section`
  width: 100%;
  margin: 0 auto;
  position: relative;
  background-color: white;
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  max-height: 680px;
  background-color: ${colors.lightGray};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 80px 32px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 36px;
  color: ${colors.dark};
  text-align: center;
  width: 100%;
  max-width: 800px;
  margin-bottom: 72px;
  padding: 0 96px 48px 96px;
  border-bottom: 1px #acafb3 solid;

  span {
    color: ${colorScheme.secondary};
  }

  @media screen and (max-width: 414px) {
    font-size: 30px;
    padding: 0 0 48px 0;
  }
  @media screen and (max-width: 375px) {
    font-size: 24px;
  }
`;

export const Text = styled.article<{ signature?: boolean }>`
  width: 100%;
  max-width: 800px;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 48px;
  text-align: center;

  p {
    margin-bottom: 16px;

    :last-child {
      margin-bottom: 0;
    }
  }

  ${({ signature }) =>
    signature &&
    css`
      max-width: 530px;
    `}
`;

export const BoxesWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1270px;
  justify-content: space-between;
  margin: 0 auto 48px auto;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Box = styled.div`
  background-color: ${colors.dark};
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
  width: 100%;
  max-width: 33.33%;
  min-height: 360px;

  :last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 900px) {
    margin-right: 0;
    margin-bottom: 40px;
    max-width: 100%;

    :last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 375px) {
    padding: 24px;
  }
`;

export const BoxSubtitle = styled.h6`
  font-family: ${fonts.Muli};
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: ${colorScheme.secondary};
  margin-bottom: 16px;
`;

export const BoxTitle = styled.h3`
  font-size: 32px;
  text-align: center;
  color: white;
  margin-bottom: 16px;

  @media screen and (max-width: 375px) {
    font-size: 24px;
  }
`;

export const BoxText = styled.article`
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: white;

  @media screen and (max-width: 375px) {
    font-size: 16px;
  }
`;

export const Subtitle = styled.h3`
  width: 100%;
  max-width: 800px;
  padding: 48px 96px 0 96px;
  font-size: 36px;
  color: ${colors.dark};
  text-align: center;
  margin-bottom: 24px;
  border-top: 1px #acafb3 solid;

  @media screen and (max-width: 414px) {
    font-size: 30px;
    padding: 48px 0 0 0;
  }
  @media screen and (max-width: 375px) {
    font-size: 24px;
  }
`;

export const Signature = styled.img.attrs({ src: ass })`
  width: 100%;
  height: 100%;
  max-width: max-content;
  max-height: max-content;
`;
